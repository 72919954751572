'use client'

import { useEffect } from 'react'

export const gtmPageView = () => {
  return window.dataLayer?.push({
    event: 'page_view',
    url: window.location.href,
  })
}

export const useGtmOnPageView = () => {
  useEffect(() => {
    gtmPageView()
  }, [])
}
