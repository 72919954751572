import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { TitleProps } from './title.types'
import { titleClassName } from './title.styles'
import { cn } from '~/utils/cn'

export const Title: React.FC<TitleProps> = ({ asChild, size, type, center, className, ...props }) => {
  const Comp = asChild ? Slot : 'h1'

  return (
    <Comp
      className={cn(titleClassName({ size, center, type }), className)}
      {...props}
    />
  )
}
