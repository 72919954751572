import React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from '~/utils/cn'

interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {}

export const Label: React.FC<LabelProps> = (props) => (
  <LabelPrimitive.Root
    {...props}
    className={cn('text-sm/6 text-neutral-primary', props.className)}
  />
)
