'use client'

import { useSession } from 'next-auth/react'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { VIEW_TYPE } from '~/scalis-components/core/calendar/calendar.constants'
import { NAVIGATE_TYPE, NavigateType, ViewType } from '~/scalis-components/core/calendar/calendar.types'

import { useCalendarRefs } from './calendar-refs-context'

interface CalendarViewContextProps {
  view: ViewType
  changeView: (viewType?: ViewType) => void
  isVerticalResources: boolean
  setIsVerticalResources: (value: boolean) => void
  showWeekend: boolean
  toggleShowWeekend: () => void
  navDateTitle: string
  selectedDate: Date
  selectDate: (date?: Date) => void
  navigateCalendar: (type: NavigateType) => void

  timezone: string
  changeTimezone: (timezone: string) => void
}

export const CalendarViewContext = createContext<CalendarViewContextProps>({
  view: VIEW_TYPE.MONTH,
  changeView: () => {},
  isVerticalResources: false,
  setIsVerticalResources: () => {},
  showWeekend: false,
  toggleShowWeekend: () => {},
  navDateTitle: '',
  selectedDate: new Date(),
  selectDate: () => {},
  navigateCalendar: () => {},
  timezone: '',
  changeTimezone: () => {},
})

export const CalendarViewProvider = ({ children }: React.PropsWithChildren) => {
  const { data: session } = useSession()
  const [view, setView] = useState<ViewType>(VIEW_TYPE.MONTH)
  const [isVerticalResources, setIsVerticalResources] = useState<boolean>(false)
  const [showWeekend, setShowWeekend] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [navDateTitle, setNavDateTitle] = useState<string>('')
  const [timezone, setTimezone] = useState<string>(
    session?.user?.calendarSettings?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  )
  const { mainCalendarRef, drawerCalendarRef } = useCalendarRefs()

  const calendarRef = isVerticalResources ? drawerCalendarRef : mainCalendarRef

  const changeTimezone = (timezone: string) => setTimezone(timezone)

  const toggleShowWeekend = () => setShowWeekend(!showWeekend)
  const changeView = (viewType?: ViewType) => {
    const currentView = viewType ?? view
    switch (currentView) {
      case VIEW_TYPE.MONTH:
        calendarRef?.current?.getApi().changeView('dayGridMonth')
        break
      case VIEW_TYPE.WEEK:
        calendarRef?.current?.getApi().changeView('timeGridWeek')
        break
      case VIEW_TYPE.DAY:
        if (isVerticalResources) {
          calendarRef?.current?.getApi().changeView('resourceTimeGridDay')
        } else {
          calendarRef?.current?.getApi().changeView('timeGridDay')
        }
        break
    }
    setView(currentView)
  }
  const selectDate = useCallback((date?: Date) => {
    if (date) setSelectedDate(date)
  }, [])
  const changeNavDateTitle = useCallback((date: Date) => {
    const title = date.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    })

    setNavDateTitle(title)
  }, [])
  const navigateCalendar = (type: NavigateType) => {
    if (type === NAVIGATE_TYPE.NEXT) calendarRef?.current?.getApi().next()
    else calendarRef?.current?.getApi().prev()

    changeNavDateTitle(calendarRef.current.getApi().getDate())
  }

  // Covers an edgecase where a user decides to switch between month-week-day in drawer
  // update view accordingly when switching back to main calendar
  const previousVerticalResources = useRef(isVerticalResources)
  useEffect(() => {
    if (previousVerticalResources.current !== isVerticalResources) {
      setTimeout(() => {
        changeView()
      }, 1)
    }
    previousVerticalResources.current = isVerticalResources
  }, [isVerticalResources])

  useEffect(() => {
    setSelectedDate(new Date())
  }, [])

  useEffect(() => {
    if (calendarRef?.current) {
      changeNavDateTitle(calendarRef.current.getApi().getDate())
    }
  }, [calendarRef])

  // https://ah-mahir.medium.com/understanding-reacts-flushsync-warning-and-how-to-handle-it-with-settimeout-9442f6dd4ed9
  useEffect(() => {
    if (selectedDate) {
      setTimeout(() => {
        calendarRef?.current?.getApi().gotoDate(selectedDate)
        changeNavDateTitle(selectedDate)
      }, 1)
    }
  }, [selectedDate])

  const value = useMemo(
    () => ({
      view,
      changeView,
      isVerticalResources,
      setIsVerticalResources,
      showWeekend,
      toggleShowWeekend,
      navDateTitle,
      selectedDate,
      selectDate,
      navigateCalendar,
      timezone,
      changeTimezone,
    }),
    [
      view,
      changeView,
      isVerticalResources,
      setIsVerticalResources,
      showWeekend,
      toggleShowWeekend,
      navDateTitle,
      selectedDate,
      selectDate,
      navigateCalendar,
      timezone,
      changeTimezone,
    ],
  )

  return <CalendarViewContext.Provider value={value}>{children}</CalendarViewContext.Provider>
}

export const useCalendarView = () => {
  const context = useContext(CalendarViewContext)
  if (!context) {
    throw new Error('useCalendarView must be used within CalendarViewProvider')
  }
  return context
}
