import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const DashboardIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.48205 2 2 6.4718 2 12C2 17.5282 6.48205 22 12 22C17.518 22 22 17.518 22 12C22 6.48205 17.5282 2 12 2Z'
        fill={primaryColor}
      />
      <path
        d='M16.8308 8.6252L14.1846 11.2611C14.3488 11.7329 14.3488 12.2457 14.1846 12.7996C14.0308 13.3124 13.6718 13.7637 13.1898 14.0098C11.1488 15.056 9.10773 13.1996 9.84619 11.138C10.1026 10.4303 10.718 9.90725 11.4462 9.7534C11.9077 9.6611 12.3385 9.68161 12.7282 9.81494L15.3641 7.16879C15.7641 6.76879 16.4205 6.76879 16.8205 7.16879C17.2205 7.56879 17.2205 8.2252 16.8205 8.6252H16.8308Z'
        fill={secondaryColor}
      />
      <path
        d='M12 6.87179C9.16927 6.87179 6.87184 9.16923 6.87184 12C6.87184 12.5744 6.4103 13.0256 5.8462 13.0256C5.2821 13.0256 4.82056 12.5744 4.82056 12C4.82056 8.04102 8.03081 4.82051 12 4.82051C12.5641 4.82051 13.0257 5.28205 13.0257 5.84615C13.0257 6.41025 12.5641 6.87179 12 6.87179Z'
        fill={secondaryColor}
      />
    </svg>
  )
}
