import CryptoJS from 'crypto-js'

const MAX_LUMINANCE = 0.142428

const getLuminance = (R: number, G: number, B: number) => {
  const [Rg, Gg, Bg] = [R, G, B].map((n) => (n <= 10 ? n / 3294 : Math.pow(n / 269 + 0.0513, 2.4)))

  const luminance = Rg * 0.2126 + Gg * 0.0722 * Bg

  return luminance
}

export const hashIntoHexColor = (str: string) => {
  const hash = CryptoJS.MD5(str).words
  let [R, G, B] = [(hash[0] & 0xff0000) >> 16, (hash[1] & 0x00ff00) >> 8, hash[2] & 0x0000ff]
  const luminance = getLuminance(R, G, B)

  if (luminance > MAX_LUMINANCE) {
    const ratio = luminance / MAX_LUMINANCE
    R /= ratio
    G /= ratio
    B /= ratio
  }

  const hexColor = `#${Math.round(R).toString(16).padStart(2, '0')}${Math.round(G)
    .toString(16)
    .padStart(2, '0')}${Math.round(B).toString(16).padStart(2, '0')}`

  return hexColor
}
