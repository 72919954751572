'use client'

import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useEffect, useState } from 'react'

import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/v2/Tooltip'
import { MILESTONE_TYPE_COLORS } from '~/scalis-components/core/sidebar/components/job-requisitions/components/job-menu-item/job-menu-item.constants'
import { cn } from '~/utils/cn'
import { LEVELS } from '..'
import { useIsHighlighted } from './hooks/use-is-highlighted'
import { useIsOpen } from './hooks/use-is-open'
import { JobMenuItemProps } from './job-menu-item.types'
import { getHref } from './job-menu-item.utils'

export const JobMenuItem: React.FC<JobMenuItemProps & { jobId: number; milestoneType?: string }> = ({
  id,
  label,
  level,
  subItems = [],
  jobId,
  open: defaultOpen,
  selectedItem,
  milestoneId,
  milestoneType,
  pipelineId,
}) => {
  const searchParams = useSearchParams()
  const isHighlighted = useIsHighlighted(id, searchParams!)
  const getIsOpen = useIsOpen(level, id, selectedItem)

  const [open, setOpen] = useState(getIsOpen)

  useEffect(() => {
    setOpen(getIsOpen)
  }, [selectedItem, id, jobId, getIsOpen])

  const toggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const milestoneTypeColor = MILESTONE_TYPE_COLORS[milestoneType || '01'] as string

  return (
    <>
      <div className='group flex w-full items-center'>
        <button
          className={cn('flex size-6 items-center justify-center text-xl text-icon-neutral-10', {
            invisible: subItems.length === 0,
          })}
          onClick={toggle}
          data-testid='expand-button'
          disabled={subItems.length === 0}
        >
          <i
            className={cn('fa-solid fa-caret-right rotate-0 transition-transform', {
              'rotate-90': open && subItems.length > 0,
            })}
          />
        </button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              aria-label='job entry'
              href={getHref(level, id, jobId, milestoneId, pipelineId) ?? ''}
              className={cn(
                'flex h-6 min-h-6 w-full items-center gap-1.5 rounded-lg pl-1.5 text-sm text-neutral-secondary hover:bg-primary-background active:bg-primary-background-hover active:font-medium active:text-brand-primary-rest',
                {
                  'bg-primary-background-hover text-brand-primary-rest': isHighlighted,
                },
              )}
            >
              {level === LEVELS.stage ? (
                <div className={`rounded-sm bg-extra-${milestoneTypeColor} size-3`} />
              ) : (
                <div className='size-0.5' />
              )}
              <span className='line-clamp-1 text-left'>{label}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent>{label}</TooltipContent>
        </Tooltip>
      </div>
      {open && (
        <div className='flex flex-col gap-1 pl-6'>
          {subItems.map((item) => (
            <JobMenuItem
              key={item.id}
              {...item}
              jobId={jobId}
              id={item.id}
              open={defaultOpen}
            />
          ))}
        </div>
      )}
    </>
  )
}
