'use client'
import { CompanyBasicInfo, Job } from '@prisma/client'
import Link from 'next/link'
import useSourceJob from '~/hooks/jobseeker/useSourceJob'
import BrandedHeader from '../BrandedHeader'
import { ScalisLogo } from './ScalisLogo'
import NavbarTemplate from '~/scalis-components/core/navbar/navbar-template/navbar-template'

type Props = {
  forceScalis?: boolean
  data?: (Job & { company: CompanyBasicInfo | null }) | null
}

export const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children, forceScalis, data }) => {
  const { sourceJob, origin } = useSourceJob()

  return (
    <div className='flex min-h-screen flex-col'>
      {origin === 'external' ? (
        <BrandedHeader
          companyId={data?.company?.id}
          companyName={data?.company_name}
        />
      ) : (
        <NavbarTemplate />
      )}
      <div className='relative flex flex-1 flex-col place-items-center justify-center gap-2 bg-background-blue px-6 py-2'>
        {sourceJob && (
          <span
            className='max-w-[26rem] text-center text-sm text-[#595959]'
            aria-label='Application for job'
          >
            Application for {data?.job_title}
          </span>
        )}
        <div className='flex w-full max-w-[25rem] flex-col rounded-xl bg-white px-8 py-8'>{children}</div>
        <div className='flex w-full max-w-[26rem] flex-col items-center justify-between gap-1'>
          <span className='text-xs text-[#595959]'>©2024 SCALIS, LLC. All Rights Reserved.</span>
          {sourceJob && origin === 'external' && (
            <span className='flex gap-2 text-xs text-[#595959]'>
              Powered by{' '}
              <Link href='/'>
                <ScalisLogo
                  height={12}
                  width={57.6}
                />
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
