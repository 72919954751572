import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

class DatadogSingleton {
  private static instance: DatadogSingleton
  private isInitialized = false

  public logs = datadogLogs
  public rum = datadogRum

  private constructor() {}

  public static getInstance(): DatadogSingleton {
    if (!DatadogSingleton.instance) {
      DatadogSingleton.instance = new DatadogSingleton()
    }
    return DatadogSingleton.instance
  }

  public initialize() {
    if (this.isInitialized) return
    this.isInitialized = true

    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APP_ID
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
    const datadogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV
    const appUrl = process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL

    if (!applicationId || !clientToken || !datadogEnv || !appUrl) {
      console.error('Datadog configuration missing.')
      return
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: `scalis-${datadogEnv}`,
      env: datadogEnv,
      sessionSampleRate: datadogEnv === 'production' ? 100 : 10,
      sessionReplaySampleRate: datadogEnv === 'production' ? 20 : 0,
      trackSessionAcrossSubdomains: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [appUrl],
      enableExperimentalFeatures: ['logs'],
    })

    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      service: `scalis-${datadogEnv}`,
      env: datadogEnv,
      sendLogsAfterSessionExpiration: true,
      trackSessionAcrossSubdomains: true,
      forwardErrorsToLogs: false,
    })
  }
}

export const Datadog = DatadogSingleton.getInstance()
