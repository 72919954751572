import Link from 'next/link'
import { Routes } from '~/src/app/_constants/routes'
import { cn } from '~/utils/cn'

interface LoginButtonProps {
  className?: string
}

export function LoginButton({ className }: LoginButtonProps) {
  return (
    <Link
      href={Routes.auth.login}
      className={cn(
        // Base styles
        'inline-flex select-none items-center justify-center gap-2',
        'rounded-xl text-sm font-medium leading-6',
        'whitespace-nowrap text-center',
        'h-10 w-auto px-4 py-2',

        // Colors and states
        'bg-button-subtle-rest',
        'hover:bg-button-subtle-hover',
        'active:bg-button-subtle-pressed',
        'border border-solid border-neutral-rest',

        // Disabled state
        'disabled:bg-button-subtle-rest',
        'disabled:border-neutral-disabled',
        'disabled:text-neutral-disabled',
        className,
      )}
    >
      <div className='relative flex w-full items-center justify-center gap-2'>
        Login
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
            fill='currentColor'
          />
          <path
            d='M10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4Z'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
          />
          <path
            d='M4 15.6667C4.93333 13.9333 7.2 12.6667 10 12.6667C12.8 12.6667 15.0667 13.9333 16 15.6667'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
          />
        </svg>
      </div>
    </Link>
  )
}
