import { tv } from 'tailwind-variants'

export const matchScore = tv({
  base: 'flex size-6 items-center justify-center rounded-full',
  variants: {
    color: {
      '8': 'bg-score-colors-01',
      '7': 'bg-score-colors-02',
      '6': 'bg-score-colors-03',
      '5': 'bg-score-colors-04',
      '4': 'bg-score-colors-05',
      '3': 'bg-score-colors-06',
      '2': 'bg-score-colors-07',
      '1': 'bg-score-colors-08',
    },
  },
})
