'use client'

import { avatarClassNames } from './company-avatar.styles'
import { CompanyAvatarProps } from './company-avatar.types'
import { cn } from '~/utils/cn'

export const CompanyAvatar = ({ imageSrc, name, size = 'lg', avatarClassName }: CompanyAvatarProps) => {
  const { container, wrapper, image, icon } = avatarClassNames({
    size,
    fallback: !imageSrc,
  })
  return (
    <div className={cn(container(), avatarClassName)}>
      <div className={wrapper()}>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={name}
            className={image()}
          />
        ) : (
          <i
            role='img'
            className={icon()}
          />
        )}
      </div>
    </div>
  )
}
