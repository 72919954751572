import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

import { CalendarEventsProvider } from './calendar-events-context'
import { CalendarRefsProvider } from './calendar-refs-context'
import { CalendarViewProvider } from './calendar-view-context'

export const CalendarProvider = ({ children }: React.PropsWithChildren) => {
  const { data: session } = useSession()
  const isCompanyUser = useMemo(() => !!session?.user.workspace && !!session?.user.workspace.currentCompany, [session])

  if (!isCompanyUser) {
    return <>{children}</>
  }

  return (
    <CalendarRefsProvider>
      <CalendarViewProvider>
        <CalendarEventsProvider>{children}</CalendarEventsProvider>
      </CalendarViewProvider>
    </CalendarRefsProvider>
  )
}
