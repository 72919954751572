import Link from 'next/link'
import React from 'react'
import { useSession } from 'next-auth/react'
import { useNavbarContext } from '../../navbar-context'
import { Routes } from '~/src/app/_constants/routes'
import { useSubdomainRouter } from '~/hooks/use-subdomain-router'

export const WorkspacesDropdownMenuFooter: React.FC = () => {
  const { setCurrentWorkspace } = useNavbarContext()
  const { update } = useSession()
  const { push } = useSubdomainRouter()

  const handleSwitchToJobSeeker = async () => {
    await update({ subdomain: null })
    setCurrentWorkspace(null)
    push(Routes.jobseeker.dashboard, '')
  }

  return (
    <Link
      href='#'
      onClick={handleSwitchToJobSeeker}
      className='flex w-full items-center justify-between gap-1 p-4 text-brand-primary-rest hover:cursor-pointer'
    >
      <div className='flex items-center gap-2 text-sm font-medium'>Switch to Job Seeker</div>
      <i className='fa-regular fa-arrow-right-from-bracket' />
    </Link>
  )
}
