export const getSignedUrl = async ({ key, name }: { key: string; name?: string }) => {
  try {
    const response = await fetch(`${'/api/generateTemporaryUrl?key='}${key}`)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return { ...data, name }
  } catch (error) {
    if (error instanceof Error) {
      throw error.message
    } else {
      throw 'An unexpected error occurred'
    }
  }
}
