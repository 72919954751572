import React from 'react'

import { statusTagStyle } from './status-tag.styles'
import { StatusStyleOption, StatusTagColors, StatusTagProps } from './status-tag.types'

export const StatusTag: React.FC<StatusTagProps> = ({
  text,
  status = StatusTagColors.green,
  className,
  contentContainerClassName,
  statusIconClassName,
  small,
  ...props
}) => {
  const { contentContainer, tag, textContent, statusIcon } = statusTagStyle({
    status: status as StatusStyleOption,
    small,
  })

  const [customColor, setCustomColor] = React.useState('')

  React.useEffect(() => {
    if (status === StatusTagColors.custom) {
      setCustomColor(props.customColor ?? '')
    }
  }, [status, props.customColor])

  const customColorStyle = { style: { backgroundColor: customColor } }

  return (
    <div className={tag({ class: className })}>
      <div className={contentContainer({ class: contentContainerClassName })}>
        <span
          className={statusIcon({ class: statusIconClassName })}
          aria-label={props['aria-label']}
          role='img'
          {...customColorStyle}
        />
        <div
          className={textContent()}
          aria-label={props['aria-label']}
        >
          {text}
        </div>
      </div>
    </div>
  )
}
