import { PropsWithChildren } from 'react'
import { cn } from '~/utils/cn'
import { ButtonIcon } from '../button-icon'
import { ActionButton } from '../button/action'
import { AccordionContent, AccordionGroup, AccordionItem, AccordionTrigger } from './accordion-group'
import { accordion } from './accordion.styles'
import { AccordionProps, isIconAction, TextAction } from './accordion.types'

export const Accordion = ({
  title,
  titleClassName,
  titleComponent,
  children,
  startAdornment,
  endSlot,
  actions = [],
  actionButtonItems,
  defaultOpen = true,
  collapsible = true,
  padded = true,
  size,
  className,
  contentClassName,
  headerClassName,
}: PropsWithChildren<AccordionProps>) => {
  const { header } = accordion({ size })

  return (
    <AccordionGroup
      type='single'
      collapsible={collapsible}
      defaultValue={defaultOpen ? 'item-1' : ''}
    >
      <AccordionItem
        className={className}
        value='item-1'
      >
        <div className={header({ className: headerClassName })}>
          <div className='flex flex-row items-center gap-2'>
            {startAdornment}

            {title && <h2 className={cn('text-base/6 font-medium tracking-tighter', titleClassName)}>{title}</h2>}

            {titleComponent}
          </div>

          <div className='flex items-center gap-2'>
            {endSlot}

            {actions.length > 0 && (
              <div className='flex items-center gap-1'>
                {actions.map((action) => {
                  if (isIconAction(action)) {
                    return (
                      <ButtonIcon
                        key={action.icon}
                        onClick={action.onClick}
                        icon={action.icon}
                        aria-label={action?.['aria-label']}
                        disabled={action.disabled}
                      />
                    )
                  }

                  const textAction = action as TextAction
                  return (
                    <span
                      key={textAction.text}
                      onClick={textAction.onClick}
                      aria-label={textAction?.['aria-label']}
                      className='font-inter cursor-pointer text-[14px] font-medium leading-[24px] tracking-[-0.6px] text-brand-primary-rest'
                    >
                      {textAction.text}
                    </span>
                  )
                })}
              </div>
            )}

            {actionButtonItems && (
              <ActionButton
                actionsItems={actionButtonItems}
                asButtonIcon
              />
            )}

            {collapsible && <AccordionTrigger />}
          </div>
        </div>

        <AccordionContent
          className={contentClassName}
          hasContentPadding={padded}
        >
          {children}
        </AccordionContent>
      </AccordionItem>
    </AccordionGroup>
  )
}
