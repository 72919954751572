import { useEffect, useState } from 'react'
import { theme } from '~/tailwind.config'

const breakpoints = theme?.extend?.screens
type Breakpoint = keyof typeof breakpoints

const useBreakpoint = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number
    height: number
  }>()
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const breakpointVerifiers: Record<Breakpoint, boolean> = Object.keys(breakpoints).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: (windowSize?.width || 0) >= parseInt(breakpoints[cur as Breakpoint]),
    }),
    {} as Record<Breakpoint, boolean>,
  )
  return { ...breakpointVerifiers, loaded: !!windowSize }
}
export default useBreakpoint
