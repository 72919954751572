import { PropsWithChildren } from 'react'
import { cn } from '~/utils/cn'
import { emptyState } from './empty-state.styles'
import { EmptyStateProps } from './empty-state.types'

export const EmptyState = ({
  icon,
  iconSize = 'text-2xl',
  iconContainerClassName = 'size-22',
  message,
  title,
  style,
  className,
  children,
}: PropsWithChildren<EmptyStateProps>) => {
  const { containerStyle, childrenStyle, iconStyle, iconContainerStyle, contentStyle, messageStyle, titleStyle } =
    emptyState()

  const messages = Array.isArray(message) ? message : [message]

  return (
    <div className={cn(containerStyle(), className)}>
      {icon && (
        <div className={cn(iconContainerStyle(), iconContainerClassName)}>
          <i
            aria-hidden
            className={cn(iconStyle(), icon, iconSize)}
            data-testid='empty-state-icon'
            style={style}
          />
        </div>
      )}
      <div className={contentStyle()}>
        <h2 className={titleStyle()}>{title}</h2>
        {messages.map((message, index) => (
          <p
            key={index}
            className={messageStyle()}
          >
            {message}
          </p>
        ))}
      </div>
      {children && <div className={childrenStyle()}>{children}</div>}
    </div>
  )
}
