import { tv } from 'tailwind-variants'

export const radioGroupStyles = tv({
  slots: {
    container:
      'relative flex w-full cursor-pointer items-center justify-center rounded-xl border border-neutral-10 p-3 transition-colors hover:bg-neutral-10',
    iconContainer: 'flex flex-1 flex-col items-center py-2',
    label: 'select-none select-none text-sm/6 font-normal text-neutral-primary',
    radioButton: 'absolute left-3 top-3',
  },
  variants: {
    checked: {
      true: {
        container: 'bg-neutral-20',
      },
    },
    disabled: {
      true: {
        container: 'cursor-not-allowed hover:border-neutral-10 hover:bg-transparent',
        label: 'text-neutral-disabled',
      },
    },
  },
})
