'use client'

import { useCallback } from 'react'
import { LEVELS } from '../..'

export const useIsOpen = (level: string, id: number, selectedItem: any) => {
  return useCallback(() => {
    if (level === LEVELS.job && id !== selectedItem?.job) {
      return false
    }
    if (selectedItem) {
      return selectedItem?.[level] === id
    }
    return true
  }, [level, id, selectedItem])
}
