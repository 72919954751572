import ExtendedLogo from '~/public/images/SCALIS_LOGO_BLUE.svg'
import CircleLogo from '~/public/images/scalis-compact-logo.svg'
import { Routes } from '~/src/app/_constants/routes'

const CIRCLE_LOGO_WIDTH = 36
const CIRCLE_LOGO_HEIGHT = 36
const EXTENDED_LOGO_WIDTH = 96
const EXTENDED_LOGO_HEIGHT = 20

export const getCompanyLogoProps = (isExtendedLogo: boolean) => {
  if (isExtendedLogo) {
    const src = ExtendedLogo ?? ''
    const width = EXTENDED_LOGO_WIDTH
    const height = EXTENDED_LOGO_HEIGHT
    return {
      src,
      width,
      height,
    }
  }
  const src = CircleLogo ?? ''
  const width = CIRCLE_LOGO_WIDTH
  const height = CIRCLE_LOGO_HEIGHT
  return {
    src,
    width,
    height,
  }
}

export const shouldRenderSearchMenu = (isSignedIn: boolean, pathname: string | null, breakpoint: boolean) => {
  return isSignedIn && pathname !== Routes.jobs_view && !pathname?.includes('/job') && breakpoint
}
