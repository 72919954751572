import { ReactNode, SelectHTMLAttributes } from 'react'

export enum SelectKind {
  error = 'error',
  default = 'default',
  filled = 'filled',
  disabled = 'disabled',
}

export enum SelectSize {
  medium = 'medium',
  large = 'large',
  small = 'small',
  xsmall = 'xsmall',
  variable = 'variable',
}

export enum SelectVariant {
  default = 'default',
  inline = 'inline',
  filter = 'filter',
  withIcon = 'withIcon',
}

export type Option = {
  label: string
  description?: string
  value: string
  icon?: ReactNode
  disabled?: boolean
  disabledLabel?: string
}

type ViewType = 'list' | 'badge'

export interface SelectProps
  extends Omit<Partial<SelectHTMLAttributes<HTMLSelectElement>>, 'onChange' | 'size' | 'onBlur' | 'onSelect'> {
  emptyTerm?: string
  options: readonly Option[]
  searchPlaceholder?: string
  setValue?: (name: string, value: string | string[], props?: any) => void
  value?: string[] | string
  selectedValue?: string
  collisionPadding?: number
  containerClassName?: string
  dataType?: string
  disabledLabel?: string
  error?: string
  icon?: ReactNode
  innerContainerClassName?: string
  label?: string
  multi?: boolean
  onBlur?: () => void
  onChange?: (value: string) => void
  onInputChange?: (value: string) => void
  onSelect?: (value: string) => void
  placeholder?: string
  required?: boolean
  searchByLabel?: boolean
  size?: SelectSize
  startAdornment?: React.ReactNode
  viewType?: ViewType
  open?: boolean
  optional?: boolean
  scrollHeight?: string
  emptyIcon?: ReactNode
  tooltip?: string
  variant?: SelectVariant
  setIsDropListOpen?: (value: boolean) => void
  popover?: (options: { closePopover: () => void }) => ReactNode
  hideSelectedValue?: boolean
  optionClassName?: string
  isOptionDisabled?: (option: Option) => boolean
}
