import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { ButtonSize, ButtonWidth } from '../button.types'

export const baseButton = tv({
  base: cn(
    'inline-flex select-none items-center justify-center gap-2 rounded-xl',
    'text-center text-sm font-medium leading-6',
  ),
  variants: {
    width: {
      [ButtonWidth.auto]: 'w-auto',
      [ButtonWidth.half]: 'w-1/2',
      [ButtonWidth.full]: 'w-full text-center',
    },
    size: {
      [ButtonSize.medium]: 'h-10 px-4 py-2',
      [ButtonSize.small]: 'h-8 px-4 py-1',
      [ButtonSize.xs]: 'h-8 px-3 py-1',
    },
    loading: {
      true: 'disabled:bg-button-disabled disabled:text-neutral-disabled',
    },
  },
  defaultVariants: {
    size: ButtonSize.medium,
    width: ButtonWidth.auto,
  },
})
