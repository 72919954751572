import { ButtonIcon } from '~/scalis-components/core'
import { HeaderActions, JobMenu } from './components'
import { JobRequisitionsProvider } from './context'

interface JobRequisitionsProps {
  showContent: boolean
  expand: () => void
}

export const JobRequisitions = ({ showContent, expand }: JobRequisitionsProps) => {
  return (
    <JobRequisitionsProvider>
      <div className='flex flex-1 flex-col p-2'>
        <HeaderActions
          showContent={showContent}
          expand={expand}
        />
        {showContent && <JobMenu expand={expand} />}
      </div>
    </JobRequisitionsProvider>
  )
}
