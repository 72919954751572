import { cn } from '~/utils/cn'
import { SeparatorProps } from './separator.types'

export const Separator = ({ done }: SeparatorProps) => {
  return (
    <hr
      className={cn('h-1 w-full min-w-8 flex-1 bg-neutral-30', {
        'bg-button-brand-primary-dark-hover': done,
      })}
    />
  )
}
