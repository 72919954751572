import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const SidebarOpenIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3Z'
        fill={secondaryColor}
      />
      <rect
        x='6'
        y='6'
        width='3'
        height='12'
        rx='1.5'
        fill={primaryColor}
      />
    </svg>
  )
}
