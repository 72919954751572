import * as SwitchPrimitives from '@radix-ui/react-switch'
import { ElementRef, forwardRef } from 'react'
import { ChangeHandler } from 'react-hook-form'
import { cn } from '~/utils/cn'

interface Props extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  onChange?: ChangeHandler
}

const SwitchButton = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, Props>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer items-center rounded-full',
      'border-4 border-transparent transition-colors',
      'focus-visible:ring-ring focus-visible:ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      'disabled:cursor-not-allowed disabled:data-[state=checked]:bg-button-disabled disabled:data-[state=unchecked]:bg-button-disabled',
      'data-[state=unchecked]:bg-background-muted',
      className,
    )}
    {...props}
    ref={ref}
    onCheckedChange={(checked) => {
      props.onCheckedChange?.(checked)
      props.onChange?.({ target: { value: checked, name: props.name } })
    }}
    onChange={console.log}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-4 w-4 rounded-full bg-white shadow-lg ring-0 transition-transform',
        'data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
      )}
    />
  </SwitchPrimitives.Root>
))
SwitchButton.displayName = SwitchPrimitives.Root.displayName

export { SwitchButton }
