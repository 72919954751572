export const getInitials = (fullName: null | string = '') => {
  const sanitizedFullName = fullName ?? ''
  const words = sanitizedFullName.split(' ')
  if (words.length === 1) return sanitizedFullName[0]?.toUpperCase() || ''

  const [firstName] = words
  const lastName = words.pop()

  return `
    ${firstName[0] || ''}${lastName?.[0] || ''}`.toUpperCase()
}
