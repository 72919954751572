import { Icon } from '~/scalis-components/core/icon'
import { cn } from '~/utils/cn'
import { roundToClosestHalf } from './star.utils'
import { StarProps } from './star.types'

export const Star = ({ status, clickable }: StarProps) => {
  const roundedStatus = roundToClosestHalf(status)

  return (
    <div className='flex size-4 items-center justify-center'>
      <Icon
        className={cn('rouded-none h-auto w-auto p-0 hover:bg-transparent active:bg-transparent', {
          'cursor-default': !clickable,
        })}
        icon={cn('text-xs fa-solid', {
          'fa-star text-status-warning': roundedStatus >= 1,
          'fa-star-half-stroke text-status-warning': roundedStatus === 0.5,
          'fa-star text-neutral-disabled': roundedStatus === 0,
        })}
      />
    </div>
  )
}
