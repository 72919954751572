import { tv } from 'tailwind-variants'

export const avatarClassNames = tv({
  slots: {
    container: 'box-border shrink-0 overflow-hidden border border-neutral-00 bg-neutral-20',
    wrapper: 'h-full w-full',
    image: 'h-full w-full object-contain',
    icon: 'fa-regular fa-building text-icon-neutral-10',
  },
  variants: {
    size: {
      xs: {
        container: 'h-7 w-7 rounded-lg',
        icon: 'fa-xs',
      },
      sm: {
        container: 'h-8 w-8 rounded-lg',
        icon: 'fa-1x',
      },
      md: {
        container: 'h-10 w-10 rounded-xl',
        icon: 'fa-lg',
      },
      lg: {
        container: 'h-12 w-12 rounded-xl',
        icon: 'fa-xl',
      },
      xl: {
        container: 'h-14 w-14 rounded-xl',
        icon: 'fa-xl',
      },
      '2xl': {
        container: 'h-[72px] w-[72px] rounded-xl',
        icon: 'fa-2xl',
      },
      '3xl': {
        container: 'h-[88px] w-[88px] rounded-xl',
        icon: 'fa-2xl',
      },
    },
    fallback: {
      true: {
        wrapper: 'flex items-center justify-center',
      },
    },
  },
})
