import { tv } from 'tailwind-variants'

export const styles = tv({
  slots: {
    aside: 'h-full',
    nav: 'flex h-full flex-col overflow-hidden rounded-tr-2xl border-r border-t border-neutral-00 bg-neutral-00 transition-all',
  },
  variants: {
    collapsed: {
      true: {
        aside: 'w-[57px]',
        nav: 'w-[57px]',
      },
      false: {
        aside: 'w-full md:w-[216px] lg:w-[260px]',
      },
    },
    collapsible: {
      true: {
        nav: 'hover:w-[216px] lg:hover:w-[260px]',
      },
      false: {},
    },
    hovered: {
      true: {
        nav: '',
      },
      false: {},
    },
  },
})
