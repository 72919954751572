'use client'

import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import React from 'react'
import useClientSubdomain from '~/hooks/use-client-subdomain'
import useNavbarType, { NavbarType } from '~/hooks/use-navbar-type'
import useBreakpoint from '~/hooks/useBreakpoint'
import { MobileNav } from '~/scalis-components/landing/components/navbar/mobile-nav'
import { useNavLinks } from '~/scalis-components/team/navbar-variations/default/navbar-default.hooks'
import { cn } from '~/utils/cn'

import ScalisLogo from '../logo/scalis-logo'
import { SearchMenu } from '../search-menu/search-menu'
import { NavbarTemplateLogoOnly } from './components'
import { NavbarButtons } from './components/navbar-buttons'
import { shouldRenderSearchMenu } from './navbar-template.utils'

export interface NavBarTemplateProps {
  isLogoOnly?: boolean
}

const NavbarTemplate = ({ isLogoOnly }: NavBarTemplateProps) => {
  const navLinks = useNavLinks()
  const { lg, md } = useBreakpoint()
  const { status, data } = useSession()
  const pathname = usePathname()
  const subdomain = useClientSubdomain()
  const navbarType = useNavbarType()
  const router = useRouter()

  const isSignedIn = status === 'authenticated' && data.user.emailVerified
  const isLoading = status === 'loading'

  if (isLogoOnly || isLoading) {
    return (
      <NavbarTemplateLogoOnly
        isLoggedOut={!isSignedIn}
        isLoading={isLoading}
      />
    )
  }

  const breakpoint = subdomain ? md : true

  const showSearchMenu = shouldRenderSearchMenu(isSignedIn, pathname, breakpoint)

  const handleOnSubmit = (data: any) => {
    router.push(`/jobs?search=${encodeURIComponent(data.search)}`)
  }

  return (
    <div
      className={cn(
        'flex w-full items-center justify-between border-b border-neutral-10 px-4 py-[0.375rem]',
        navbarType === NavbarType.COMPANY_WORKSPACE ? 'bg-brand-primary-light-00' : '',
      )}
    >
      <div className='flex w-full items-center gap-4 lg:w-auto'>
        <MobileNav />
        <ScalisLogo />

        {showSearchMenu && (
          <div className='w-full lg:w-[15.625rem]'>
            <SearchMenu onSubmit={handleOnSubmit} />
          </div>
        )}
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex gap-2'>
          {lg &&
            navLinks.map(({ label, href }, index) => (
              <Link
                role='link'
                aria-label={label}
                className={cn(
                  'whitespace-nowrap rounded-lg p-2 text-sm/6 font-medium text-neutral-primary hover:bg-brand-primary-light-10 active:bg-button-brand-primary-light-hover',
                )}
                href={href}
                key={`navbar-link-index-${index}`}
              >
                {label}
              </Link>
            ))}
        </div>

        {!isLoading && <NavbarButtons />}
      </div>
    </div>
  )
}

export default NavbarTemplate
