import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { InputVariants } from './input.types'

export const inputClassName = tv({
  slots: {
    labelStyles: 'text-neutral-primary text-sm/6 tracking-tighter',
    inputContainerStyles: cn(
      'flex flex-1 items-center gap-1 relative rounded-xl',
      'focus-within:border-brand-primary-rest',
    ),
    inputStyles: cn(
      'bg-transparent outline-none placeholder:text-neutral-tertiary px-3 py-2 h-10',
      'flex w-full rounded-xl border border-bg-neutral-10 text-sm/6 text-neutral-primary',
      'file:border-0 file:bg-transparent file:text-sm file:font-medium focus:border-brand-primary-rest',
    ),
    iconStyle: 'fa-1x',
    textAuxStyles: 'text-xs text-neutral-secondary inline-block mt-1 -tracking-tight',
    startAdornmentStyles: 'left-2',
    endAdornmentStyles: 'right-2',
  },
  compoundSlots: [
    {
      slots: ['startAdornmentStyles', 'endAdornmentStyles'],
      class: 'absolute flex size-6 items-center justify-center',
    },
  ],
  variants: {
    [InputVariants.error]: {
      true: {
        inputStyles: 'border-status-critical',
        iconStyle: 'text-status-critical',
      },
    },
    [InputVariants.disabled]: {
      true: {
        labelStyles: 'text-neutral-disabled',
        inputStyles: 'border-neutral-disabled disabled:text-neutral-disabled disabled:bg-white',
        textAuxStyles: 'text-neutral-disabled',
        iconStyle: 'text-neutral-disabled',
      },
    },
    [InputVariants.small]: {
      true: {
        inputStyles: 'py-1 px-2 gap-1 h-8',
      },
    },
    [InputVariants.medium]: {
      true: {
        inputStyles: 'py-[6px] px-2 gap-1 h-9',
      },
    },
    [InputVariants.startAdornment]: {
      true: {
        inputStyles: 'pl-9',
      },
    },
    [InputVariants.endAdornment]: {
      true: {
        inputStyles: 'pr-8',
      },
    },
  },
})
