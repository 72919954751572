'use client'

import { AccountButtonProps } from './account-button.types'
import { AccountAvatar } from './components/account-avatar'

export const AccountButton = ({ imageSrc = '', name, onClick, ...props }: AccountButtonProps) => {
  return (
    <div
      className='
      hover:border-brand-primary-dark-hover
      active:border-brand-primary-dark-pressed 
      group
      relative flex h-9 !w-9 cursor-pointer justify-center rounded-full border-2 border-neutral-10 p-0.5
      '
      onClick={onClick}
      role='button'
      aria-label='account button'
    >
      <AccountAvatar
        imageSrc={imageSrc}
        name={name}
        {...props}
      />
    </div>
  )
}
//
