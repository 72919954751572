'use client'

import { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { Datadog } from '../utils/scalis-datadog'

export default function DatadogInit() {
  useEffect(() => {
    Datadog.initialize()
  }, [])

  const { data: session } = useSession()
  const user = session?.user

  useEffect(() => {
    if (user) {
      Datadog.rum.setUser({
        id: user.id.toString(),
        name: user.email,
        email: user.email,
      })
    }
  }, [user])

  return null
}
