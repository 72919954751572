import Link from 'next/link'
import { cn } from '~/utils/cn'
import { Routes } from 'src/app/_constants/routes'

interface FindJobsButtonProps {
  className?: string
  iconClassName?: string
}

export default function FindJobsButton({ className, iconClassName }: FindJobsButtonProps) {
  return (
    <Link href={Routes.landing.findJobs}>
      <button
        className={cn(
          'flex flex-row items-center justify-center',
          'h-[72px] w-[264px]',
          'gap-2 px-2 py-5',
          'rounded-[18px]',
          'bg-white',
          'border border-neutral-30',

          'hover:bg-surface-brand-hover',
          'hover:border-2 hover:border-brand-primary-rest',

          'hover:text-brand-primary-rest',

          'active:bg-button-brand-primary-light-pressed',
          'active:border-2 active:border-brand-primary-rest',

          className,
        )}
      >
        Find Jobs
        <span className='ml-2'>
          <svg
            className={cn('h-5 w-5 fill-current', iconClassName)}
            viewBox='0 0 20 19'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M6.875 2.1875V3.75H13.125V2.1875C13.125 2.03125 12.9688 1.875 12.8125 1.875H7.1875C6.99219 1.875 6.875 2.03125 6.875 2.1875ZM5 3.75V2.1875C5 1.01562 5.97656 0 7.1875 0H12.8125C13.9844 0 15 1.01562 15 2.1875V3.75H17.5C18.8672 3.75 20 4.88281 20 6.25V10.9375V16.25C20 17.6562 18.8672 18.75 17.5 18.75H2.5C1.09375 18.75 0 17.6562 0 16.25V10.9375V6.25C0 4.88281 1.09375 3.75 2.5 3.75H5ZM1.875 11.875V16.25C1.875 16.6016 2.14844 16.875 2.5 16.875H17.5C17.8125 16.875 18.125 16.6016 18.125 16.25V11.875H12.5V12.5C12.5 13.2031 11.9141 13.75 11.25 13.75H8.75C8.04688 13.75 7.5 13.2031 7.5 12.5V11.875H1.875ZM7.5 10H12.5H18.125V6.25C18.125 5.9375 17.8125 5.625 17.5 5.625H14.0625H5.9375H2.5C2.14844 5.625 1.875 5.9375 1.875 6.25V10H7.5Z' />
          </svg>
        </span>
      </button>
    </Link>
  )
}
