import { DayPicker } from 'react-day-picker'
import { Icon } from '~/scalis-components/core/icon'
import { cn } from '~/utils/cn'

export type DaySelectorProps = React.ComponentProps<typeof DayPicker>

export const DaySelector = ({ className, classNames, showOutsideDays = true, ...props }: DaySelectorProps) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('rounded-xl p-4 text-neutral-primary', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center h-min',
        caption_label: 'text-sm font-medium mt-1',
        nav: 'space-x-1 flex items-center mt-1',
        nav_button: cn(
          'inline-flex items-center justify-center rounded-md text-sm font-medium',
          'transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
          'disabled:pointer-events-none disabled:opacity-50 bg-background',
          'hover:text-accent-foreground',
          'bg-transparent opacity-100 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-neutral-primary rounded-md w-8 font-medium select-none text-sm/6 mt-3',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          'inline-flex items-center justify-center rounded-lg text-sm font-medium',
          'transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
          'disabled:pointer-events-none disabled:opacity-50 h-9 px-3',
          'hover:text-accent-foreground hover:bg-brand-primary-light-10 hover:text-neutral-primary ',
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100 select-none',
        ),
        day_selected:
          'bg-primary text-white hover:bg-secondary-background-hover hover:text-white focus:bg-primary focus:text-white',
        day_outside: 'text-white-85',
        day_disabled: 'text-white-85',
        day_range_middle: 'aria-selected:bg-neutral-20 aria-selected:text-dark-color-text-100 rounded-none ',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <Icon icon='fa-chevron-left fa-solid text-neutral-primary' />,
        IconRight: ({ ...props }) => <Icon icon='fa-chevron-right fa-solid text-neutral-primary' />,
      }}
      {...props}
    />
  )
}
