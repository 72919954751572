import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { ButtonSize, ButtonStandaloneKind } from '../button.types'
import { baseButton } from './base-button.styles'

export const standaloneButtonClassName = tv({
  extend: baseButton,
  base: 'rounded-lg bg-transparent text-center disabled:bg-transparent disabled:text-neutral-disabled',
  variants: {
    kind: {
      [ButtonStandaloneKind.brand]: cn(
        'text-brand-primary-rest',
        'hover:bg-brand-primary-light-00',
        'active:bg-brand-primary-light-10',
      ),
      [ButtonStandaloneKind.subtle]: cn('text-neutral-primary', 'hover:bg-neutral-10', 'active:bg-neutral-20'),
      [ButtonStandaloneKind.critical]: cn('text-status-critical', 'hover:bg-neutral-20', 'active:bg-neutral-30'),
    },
    size: {
      [ButtonSize.medium]: 'p-2',
      [ButtonSize.small]: 'px-2 py-1',
      [ButtonSize.xs]: 'h-6 px-2 py-0',
    },
  },
  defaultVariants: {
    kind: ButtonStandaloneKind.brand,
  },
})
