import { cn } from '~/utils/cn'

import { Slot } from '@radix-ui/react-slot'

import { captionClassName } from './caption.styles'
import { CaptionProps } from './caption.types'

export const Caption = ({
  asChild,
  underline,
  italic,
  type,
  color,
  center,
  size,
  className,
  ...props
}: CaptionProps) => {
  const Comp = asChild ? Slot : 'span'

  return (
    <Comp
      className={cn(
        captionClassName({
          underline,
          italic,
          type,
          color,
          center,
          size,
        }),
        className,
      )}
      {...props}
    />
  )
}
