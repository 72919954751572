export const MILESTONE_TYPE = {
  SOURCING: 'SOURCING',
  APPLICATION_REVIEW: 'APPLICATION_REVIEW',
  PRE_SCREEN: 'PRE_SCREEN',
  INTERVIEWING: 'INTERVIEWING',
  OFFER: 'OFFER',
  HIRED: 'HIRED',
}

export const MILESTONE_TYPE_COLORS: Record<string, string> = {
  [MILESTONE_TYPE.SOURCING]: '01',
  [MILESTONE_TYPE.APPLICATION_REVIEW]: '02',
  [MILESTONE_TYPE.PRE_SCREEN]: '03',
  [MILESTONE_TYPE.INTERVIEWING]: '04',
  [MILESTONE_TYPE.OFFER]: '05',
  [MILESTONE_TYPE.HIRED]: '06',
}
