import { Icon } from '../../icon'
import { iconButtonClassName } from '../../icon/icon.styles'
import { HamburgerMenuButtonProps } from './hamburger-menu-button.types'

export const HamburgerMenuButton = ({ onClick, ...props }: HamburgerMenuButtonProps) => {
  return (
    <button
      type='button'
      onClick={onClick}
      aria-label={props['aria-label'] ?? 'hamburger menu button'}
      className={iconButtonClassName}
    >
      <Icon
        icon='fa-solid fa-bars'
        size={18}
      />
    </button>
  )
}
