import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { cn } from '~/utils/cn'

const RadioGroupBase = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2 [&_input]:hidden', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroupBase.displayName = RadioGroupPrimitive.Root.displayName

interface RadioGroupItemProps extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  classNamePrimitive?: string
}

const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, RadioGroupItemProps>(
  ({ className, classNamePrimitive, children, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'group aspect-square h-[18px] w-[18px] cursor-pointer overflow-hidden rounded-full ',
          'border border-bg-neutral-10 data-[state=checked]:border-none data-[state=checked]:border-bg-button-dark-00',
          'disabled:cursor-not-allowed',
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator
          className={cn(
            ' flex h-full w-full items-center justify-center border-none',
            'data-[state=checked]:bg-button-dark-00 group-disabled:data-[state=checked]:bg-button-disabled',
          )}
        >
          <div className={cn('h-1.5 w-1.5 rounded-full bg-white', classNamePrimitive)} />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    )
  },
)
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroupBase, RadioGroupItem }
