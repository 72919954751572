import { tv } from 'tailwind-variants'
import { RadioGroupType } from './radio-group.types'

export const radioGroupStyles = tv({
  slots: {
    container: 'grid grid-cols-[max-content,1fr] gap-2 items-center w-full cursor-pointer',
    label: 'select-none text-sm/6 font-normal text-neutral-primary select-none cursor-pointer',
  },
  variants: {
    type: {
      [RadioGroupType.Description]: {
        container: 'px-8 py-4 rounded-lg gap-4',
        label: 'font-semibold',
      },
      [RadioGroupType.Default]: {},
      [RadioGroupType.Outlined]: {
        container: 'py-2 px-3 border border-neutral-10 rounded-xl text-sm font-normal',
      },
      [RadioGroupType.Highlited]: {
        container: 'px-4 py-2 rounded-xl gap-2',
      },
    },
    checked: {
      true: {},
    },
    disabled: {
      true: {
        label: 'text-neutral-disabled',
      },
    },
  },
  compoundVariants: [
    {
      type: [RadioGroupType.Description, RadioGroupType.Outlined, RadioGroupType.Highlited],
      checked: true,
      class: {
        container: 'bg-neutral-20',
      },
    },
  ],
})
