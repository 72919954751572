import { tv } from 'tailwind-variants'
import { StatusTagColors } from './status-tag.types'

export const statusTagStyle = tv({
  slots: {
    tag: 'flex h-8 w-fit select-none flex-nowrap rounded-full border border-neutral-10 bg-white px-3 py-1',
    contentContainer: 'flex items-center gap-2',
    textContent: 'text-sm/6 font-medium',
    statusIcon: 'h-2 w-2 rounded-full',
  },
  variants: {
    small: {
      true: {
        tag: 'px-2',
        textContent: 'text-xs',
        contentContainer: 'h-6',
      },
    },
    status: {
      [StatusTagColors.green]: { statusIcon: 'bg-status-success' },
      [StatusTagColors.leafGreen]: { statusIcon: 'bg-[#55B137]' },
      [StatusTagColors.limeGreen]: { statusIcon: 'bg-[#BFD254]' },
      [StatusTagColors.mossGreen]: { statusIcon: 'bg-[#93CB49]' },

      [StatusTagColors.red]: { statusIcon: 'bg-status-critical' },
      [StatusTagColors.cherryRed]: { statusIcon: 'bg-[#F35D58]' },

      [StatusTagColors.yellow]: { statusIcon: 'bg-status-warning' },
      [StatusTagColors.neonYellow]: { statusIcon: 'bg-[#E1D954]' },
      [StatusTagColors.yolkYellow]: { statusIcon: 'bg-[#EDCA59]' },

      [StatusTagColors.gray]: { statusIcon: 'bg-neutral-secondary' },
      [StatusTagColors.violet]: { statusIcon: 'bg-violet-400' },
      [StatusTagColors.pink]: { statusIcon: 'bg-pink-400' },
      [StatusTagColors.teal]: { statusIcon: 'bg-teal-400' },
      [StatusTagColors.cyan]: { statusIcon: 'bg-cyan-400' },
      [StatusTagColors.criticalRest]: {
        statusIcon: 'bg-icon-status-critical-rest',
      },
      [StatusTagColors.disabled]: { statusIcon: 'bg-icon-neutral-disabled' },
    },
  },
})
