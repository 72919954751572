'use client'

import { useMemo } from 'react'

import { cn } from '~/utils/cn'
import { DynamicIcon } from '..'
import { SidebarCloseIcon, SidebarOpenIcon } from '../..'
import { useSidebar } from '../../context'
import { COLLAPSE_BUTTON_LABEL, OPEN_BUTTON_LABEL } from './collapse-button.constants'

export const CollapseButton = () => {
  const { collapsed, isActive, toggleCollapsed } = useSidebar()

  const handleToggleCollapsed = () => {
    toggleCollapsed()
  }

  const sideIcon = useMemo(() => {
    return collapsed ? SidebarCloseIcon : SidebarOpenIcon
  }, [collapsed])

  return (
    <button
      className={cn('flex w-full items-center justify-start rounded-xl p-2 hover:bg-surface-brand-rest', {
        'w-10': collapsed && !isActive,
      })}
      onClick={handleToggleCollapsed}
    >
      <div className='flex items-center gap-2'>
        <DynamicIcon icon={sideIcon} />
        <span
          className={cn(
            'max-w-full whitespace-nowrap text-sm text-neutral-primary opacity-100 transition-all duration-300',
            { 'max-w-0 opacity-0': collapsed && !isActive },
          )}
        >
          {collapsed ? OPEN_BUTTON_LABEL : COLLAPSE_BUTTON_LABEL}
        </span>
      </div>
    </button>
  )
}
