import { AvatarBase, AvatarImage, AvatarFallback } from '../avatar-base'
import { getInitials } from '~/utils/getInitials'
import { AvatarProps } from './avatar.types'
import { avatarStyles } from './avatar.styles'
import { cn } from '~/utils/cn'

export const Avatar: React.FC<AvatarProps> = ({
  id,
  fullName,
  src,
  atEnd = false,
  displayFullName = false,
  backgroundColor,
  size,
  className,
  verticalAlignment,
  subTitle,
  containerClassName,
}) => {
  const { avatar, avatarFallback, container, text, textSmall } = avatarStyles({
    size,
    verticalAlignment,
  })

  return (
    <div
      className={cn(container(), containerClassName)}
      data-testid='avatar-group-item'
    >
      <AvatarBase className={cn(avatar(), className)}>
        {!atEnd && (
          <AvatarImage
            id={String(id)}
            src={src}
            aria-label={fullName}
            alt={fullName}
          />
        )}
        <AvatarFallback
          className={avatarFallback()}
          {...(backgroundColor ? { style: { backgroundColor } } : {})}
        >
          {atEnd ? fullName : getInitials(fullName)}
        </AvatarFallback>
      </AvatarBase>

      {displayFullName && subTitle === undefined && <span className={text()}>{fullName}</span>}

      {displayFullName && subTitle !== undefined && (
        <div className='flex flex-col'>
          <span className={text()}>{fullName}</span>
          <span className={textSmall()}>{subTitle}</span>
        </div>
      )}
    </div>
  )
}
