'use client'

import { createContext, useState } from 'react'

import { SORT_BY_NEWEST_TO_OLDEST } from '../job-requisitions.constants'
import { JobRequisitionsContextProps, JobRequisitionsProviderProps } from './job-requisitions-context.types'

export const JobRequisitionsContext = createContext({} as JobRequisitionsContextProps)

export const JobRequisitionsProvider: React.FC<JobRequisitionsProviderProps> = ({ children }) => {
  const [sortBy, setSortBy] = useState(SORT_BY_NEWEST_TO_OLDEST)

  const changeSortBy = (newSortBy: string) => {
    setSortBy(newSortBy)
  }

  return (
    <JobRequisitionsContext.Provider
      value={{
        sortBy,
        changeSortBy,
      }}
    >
      {children}
    </JobRequisitionsContext.Provider>
  )
}
