'use client'

import { cn } from '~/utils/cn'
import { Button, ButtonKind, ButtonWidth } from '../../button'
import { FileItem } from './components/file-item'
import { FileInputProps } from './file-input.types'
import { useState } from 'react'

export const FileInput = ({
  accept,
  name,
  isMultiple,
  label,
  onFileChange,
  onFileRemoval,
  error: formError,
  children,
  buttonWidth = ButtonWidth.full,
  isStandalone = false,
  kind,
  showFileNames = true,
  isLoading,
  disable,
}: FileInputProps) => {
  const [files, setFiles] = useState<File[]>([])
  const [error, setError] = useState<string>('')

  const handleFileChange = (newFiles: FileList) => {
    const fileArray = Array.from(newFiles)

    try {
      onFileChange?.(newFiles)
      isMultiple ? setFiles((prev) => [...prev, ...fileArray]) : setFiles(fileArray)
    } catch (e) {
      setError('Please try again, something wrong happened!')
    }
  }

  const handleRemove = (index: number) => {
    const filteredFiles = files.filter((_, i) => i !== index)
    setFiles(filteredFiles)
    onFileRemoval?.()
  }

  const errorMessage = formError || error

  return (
    <div className='flex flex-col'>
      <div>
        <input
          type='file'
          name={name}
          accept={accept}
          multiple={isMultiple}
          className='hidden'
          id='fileInput'
          onChange={(event) => {
            if (event.target.files) {
              handleFileChange(event.target.files)
            }
          }}
          disabled={disable}
        />

        {(!showFileNames || (showFileNames && !files?.length)) &&
          (children ?? (
            <Button
              {...(isStandalone ? { isStandalone: true } : { kind: kind ? kind : ButtonKind.tertiary })}
              className={cn('relative', buttonWidth === ButtonWidth.full && 'w-full')}
              width={buttonWidth}
              type='button'
              asChild
              loading={isLoading}
              disabled={disable}
            >
              <label
                className='flex w-full cursor-pointer items-baseline justify-center gap-2'
                htmlFor='fileInput'
              >
                {label}
                <div className='size-4'>
                  <i className='fa-regular fa-upload' />
                </div>
              </label>
            </Button>
          ))}
      </div>
      <div>
        {showFileNames &&
          files?.map?.(({ name }, index) => (
            <FileItem
              key={name}
              name={name}
              handleRemove={() => handleRemove(index)}
            />
          ))}
      </div>
      {errorMessage && (
        <div
          className='mt-6 text-left text-xs text-error'
          hidden={!errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}
