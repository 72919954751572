import useNavbarType, { NavbarType } from '~/hooks/use-navbar-type'
import { ScalisLogoEmployer } from './scalis-logo-employer'
import { CompanyAvatar } from '../../company-avatar'
import Image from 'next/image'
import { getCompanyLogoProps } from '../navbar-template/navbar-template.utils'
import useBreakpoint from '~/hooks/useBreakpoint'
import SubdomainLink from '../../subdomain-link/subdomain-link'
import { Routes } from '~/src/app/_constants/routes'
import { useSubdomainCompanyInfo } from '~/src/app/jobs/hooks/use-subdomain-company-info'

const ScalisLogo = () => {
  const navbarType = useNavbarType()
  const { lg } = useBreakpoint()

  const companyInfo = useSubdomainCompanyInfo()

  if (navbarType === NavbarType.COMPANY_BRANDED) {
    return (
      <SubdomainLink
        subdomain={companyInfo?.subdomain ?? ''}
        href={Routes.jobs_view}
      >
        <CompanyAvatar
          size='md'
          imageSrc={companyInfo?.logo}
          name={companyInfo?.companyName ?? 'Company logo'}
        />
      </SubdomainLink>
    )
  } else if (navbarType === NavbarType.COMPANY_WORKSPACE) {
    return (
      <SubdomainLink
        subdomain=''
        href='/'
      >
        <ScalisLogoEmployer />
      </SubdomainLink>
    )
  }

  return (
    <SubdomainLink
      subdomain=''
      href='/'
    >
      <Image
        alt={`scalis logo ${lg ? 'extended' : 'compact'}`}
        {...getCompanyLogoProps(lg)}
      />
    </SubdomainLink>
  )
}

export default ScalisLogo
