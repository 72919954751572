import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { BadgeSize, BadgeType } from './badge.types'

export const badgeClassname = tv({
  base: cn(
    'flex justify-center items-center rounded-full',
    'font-semibold text-[10px] leading-[10px] tracking-[-0.3px]',
  ),
  variants: {
    type: {
      [BadgeType.brand]: 'bg-brand-primary-dark-00 text-neutral-on-brand',
      [BadgeType.danger]: 'bg-status-critical text-neutral-on-brand',
      [BadgeType.subtle]: 'bg-neutral-00 text-neutral-primary',
    },
    size: {
      [BadgeSize.large]: 'w-6 h-6',
      [BadgeSize.medium]: 'w-5 h-5',
    },
  },
})
