'use client'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { setCookie, getCookie, deleteCookie } from 'cookies-next'
import { ORIGIN_STORAGE_KEY, SOURCE_JOB_STORAGE_KEY } from './source-job.contants'

const useSourceJob = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const sourceJob = searchParams?.get('source_job') || null
  const origin = searchParams?.get('origin') || 'internal'
  const storeSourceJob = () => {
    if (!sourceJob) return
    setCookie(SOURCE_JOB_STORAGE_KEY, sourceJob)
    if (!origin) return
    setCookie(ORIGIN_STORAGE_KEY, origin)
  }

  const fetchSourceJob = () => {
    const storedSourceJob = getCookie(SOURCE_JOB_STORAGE_KEY)
    if (!storedSourceJob) return
    const storedOrigin = getCookie(ORIGIN_STORAGE_KEY)
    const params = new URLSearchParams(searchParams!)
    params.set('source_job', storedSourceJob)
    params.set('origin', storedOrigin as string)
    const sourceJobUrl = `${pathname}?${params.toString()}`
    router.replace(sourceJobUrl)
    deleteCookie(SOURCE_JOB_STORAGE_KEY)
    deleteCookie(ORIGIN_STORAGE_KEY)
  }

  return { storeSourceJob, fetchSourceJob, sourceJob, origin }
}

export default useSourceJob
