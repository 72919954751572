import { tv } from 'tailwind-variants'

export const emptyState = tv({
  slots: {
    containerStyle: 'flex h-full w-full flex-col flex-nowrap items-center justify-center gap-3',
    iconContainerStyle: 'flex items-center justify-center rounded-full bg-brand-primary-light-00',
    iconStyle: 'fa-regular text-brand-primary-rest',
    contentStyle: 'flex flex-col items-center justify-center',
    titleStyle: 'text-center text-base font-medium tracking-tighter text-neutral-primary',
    messageStyle: 'text-center text-sm font-normal tracking-tighter text-neutral-secondary',
    childrenStyle: 'flex items-center justify-center gap-3',
  },
})
