import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const PipelineIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2'
        y='2'
        width='8'
        height='10'
        rx='3'
        fill={primaryColor}
      />
      <rect
        x='2'
        y='14'
        width='8'
        height='7'
        rx='3'
        fill={secondaryColor}
      />
      <rect
        x='13'
        y='2'
        width='8'
        height='7'
        rx='3'
        fill={secondaryColor}
      />
      <rect
        x='13'
        y='11'
        width='8'
        height='10'
        rx='3'
        fill={primaryColor}
      />
    </svg>
  )
}
