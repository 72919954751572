'use client'

import 'swiper/css'
import Image from 'next/image'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect, useState } from 'react'

const LOGOS = [
  {
    name: 'Winc',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/winc-logo.svg',
  },
  {
    name: 'Aument.io',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/aument-logo.svg',
  },
  {
    name: 'Eco Cart',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/eco-cart-logo.svg',
  },
  {
    name: 'EHouse',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/ehouse-logo.svg',
  },
  {
    name: 'Novel',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/novel-logo.svg',
  },
  {
    name: 'Varfaj',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/varfaj-logo.svg',
    className: '-mx-[40px]',
  },
  {
    name: 'Hawk Media',
    src: 'https://scalis-assets.s3.us-east-2.amazonaws.com/public/landing/hawk-media-logo.svg',
    className: '-mx-[40px]',
  },
]

export default function Customers() {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div className='mx-auto pt-10 [&_.swiper-wrapper]:ease-linear'>
      <Swiper
        style={{ padding: '4px 0' }}
        className='h-[32px] lg:h-[56px]'
        slidesPerView={2}
        allowTouchMove={false}
        spaceBetween={40}
        loop
        speed={5000}
        autoplay={{
          delay: 0,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          768: { slidesPerView: 3, spaceBetween: 40 },
          1024: { slidesPerView: 5, spaceBetween: 60 },
        }}
      >
        {[...LOGOS, ...LOGOS].map((logo, index) => (
          <SwiperSlide
            key={`${logo.name}-${index}`}
            className={logo.className}
          >
            <Image
              fill
              alt={logo.name}
              src={logo.src}
              className={`transition-opacity duration-1000 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
