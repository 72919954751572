import { tv } from 'tailwind-variants'

export const containerClassName = tv({
  base: 'w-[53px] transition-all',
  variants: {
    open: {
      true: 'w-[285px]',
    },
  },
})

export const navClassName = tv({
  base: 'relative z-50 flex h-full w-full flex-col overflow-hidden rounded-tr-2xl border-r border-t border-neutral-00 bg-neutral-00 transition-all',
  variants: {
    openByActivity: {
      true: 'w-[285px]',
    },
  },
})
