import { tv } from 'tailwind-variants'

export const dividerClassname = tv({
  base: 'flex-[0_1_1px] w-full h-[1px] bg-neutral-30 relative',
  variants: {
    vertical: {
      true: 'w-[1px] h-full',
    },
  },
})
