'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import * as React from 'react'
import { cn } from '~/utils/cn'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    onOverlayClick?: () => void
  }
>(({ className, onOverlayClick, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    onClick={onOverlayClick}
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/50',
      className,
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

export type DialogSize = 'default' | 'small'

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    onOverlayClick?: () => void
    overlayClassName?: string
    size?: DialogSize
    hideHeader?: boolean
    hideFooter?: boolean
    hideHeaderBorder?: boolean
  }
>(({ onOverlayClick, className, children, overlayClassName, size = 'default', hideHeader, ...props }, ref) => {
  const sizeClasses = {
    default: 'xxs:max-w-[21rem] sm:max-w-lg lg:max-w-[768px]',
    small: 'max-w-[440px]',
  }

  return (
    <DialogPortal>
      <DialogOverlay
        onOverlayClick={onOverlayClick}
        className={overlayClassName}
      />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-[50%] top-[50%] z-[100] grid w-full translate-x-[-50%] translate-y-[-50%]',
          'rounded-xl bg-white shadow-lg duration-200',
          sizeClasses[size],
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
          'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]',
          'data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          hideHeader && 'border-none',
          className,
        )}
        {...props}
      >
        <VisuallyHidden.Root>
          <DialogTitle>{props.title ?? 'Dialog'}</DialogTitle>
        </VisuallyHidden.Root>
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  hideHeaderBorder,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  hideHeaderBorder?: boolean
}) => (
  <div
    className={cn(
      'flex w-full flex-row items-baseline gap-1 rounded-t-3xl bg-white px-4',
      hideHeaderBorder ? 'pt-4' : 'py-4',
      !hideHeaderBorder && 'border-b border-bg-neutral-30',
      className,
    )}
    {...props}
  />
)
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex w-full items-center justify-between rounded-b-xl border-t border-bg-neutral-30 bg-white px-4 py-3',
      className,
    )}
    {...props}
  >
    {children}
  </div>
)
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-muted-foreground text-sm', className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
