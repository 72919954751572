import { tv } from 'tailwind-variants'
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react'
import { Input } from '~/components/v2/Input'
import { cn } from '~/utils/cn'
import { Control } from 'react-hook-form'

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  error?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  helper?: string
  containerClassName?: string
  labelClassName?: string
  onValueChange?: (name: string, value: string) => void
}

const helperVariants = tv({
  base: 'text-xs flex items-center gap-1',
  variants: { state: { error: 'text-error', default: 'text-[#737373]' } },
  defaultVariants: {
    state: 'default',
  },
})

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      error,
      startAdornment,
      endAdornment,
      required,
      helper,
      className,
      containerClassName,
      labelClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn('flex flex-col gap-1', containerClassName)}>
        {label && (
          <label
            htmlFor={props.name}
            className={cn({ 'text-sm font-semibold': !labelClassName }, labelClassName)}
          >
            {label}
            {required && '*'}
          </label>
        )}
        <div className='relative'>
          <Input
            className={cn(
              `peer h-[2.625rem] border-[#595959] py-2 placeholder:text-[#999999] placeholder-shown:border-[#ccc] ${
                error && 'border-error bg-[#FFF5F5] text-error placeholder-shown:border-error'
              } ${startAdornment && 'pl-7'} ${endAdornment && 'pr-7'}`,
              className,
            )}
            placeholder=''
            id={props.name}
            {...props}
            ref={ref}
            aria-label={props.placeholder}
          />
          <span
            className={`absolute left-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999] ${
              error && 'text-error'
            }`}
          >
            {startAdornment}
          </span>
          <span
            className={`absolute right-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999] ${
              error && 'text-error'
            }`}
          >
            {endAdornment}
          </span>
        </div>
        {(error || helper) && (
          <span className={helperVariants({ state: error ? 'error' : 'default' })}>
            {error && <i className='mi-circle-error'></i>}
            {error || helper}
          </span>
        )}
      </div>
    )
  },
)

TextField.displayName = 'TextField'
