'use client'

import { Control, useController, useWatch } from 'react-hook-form'
import { cn } from '~/utils/cn'
import { Checkbox } from './checkbox'

interface Option {
  label: string
  value: string
}

interface CheckboxesProps {
  className?: string
  options: Option[]
  control: Control<any>
  name: string
  orientation?: 'horizontal' | 'vertical'
}
export const Checkboxes = ({ options, control, name, className, orientation = 'horizontal' }: CheckboxesProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  })
  const checkboxIds = useWatch({ control, name: name }) || []

  const handleChange = (value: string) => {
    const newArray = [...checkboxIds]
    const item = value

    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item)

      index === -1 ? newArray.push(item) : newArray.splice(index, 1)
    } else {
      newArray.push(item)
    }
    onChange(newArray)
  }

  const { onChange, ...rest } = field
  return (
    <div>
      <div className={cn('flex gap-4', orientation === 'vertical' ? 'flex-col' : 'flex-row', className)}>
        {options.map((option) => (
          <Checkbox
            {...rest}
            key={option.value}
            label={option.label}
            onChange={async () => handleChange(option['value'])}
          />
        ))}
      </div>
      {error && <span className='text-sm text-red-500'>{error.message}</span>}
    </div>
  )
}
