import { BodyColor, BodySize } from '../typography'
import { ButtonLinkSize, ButtonLinkType } from './button-link.types'

export const getButtonLinkBodySize = (size?: ButtonLinkSize) => {
  switch (size) {
    case ButtonLinkSize.large:
      return BodySize.medium
    case ButtonLinkSize.default:
    default:
      return BodySize.small
  }
}

export const getButtonLinkBodyColor = (type?: ButtonLinkType) => {
  switch (type) {
    case ButtonLinkType.disabled:
      return BodyColor.disabled
    case ButtonLinkType.critical:
      return BodyColor.critical
    default:
      return BodyColor.brandPrimary
  }
}
