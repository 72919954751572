import { dividerClassname } from './divider.styles'
import { DividerProps } from './divider.types'

export const Divider: React.FC<DividerProps> = ({ vertical, children }) => {
  if (children)
    return (
      <div className='grid w-full grid-cols-[1fr_7.5rem_1fr] items-center justify-center'>
        <div className={dividerClassname()} />
        {children}
        <div className={dividerClassname()} />
      </div>
    )
  return <div className={dividerClassname({ vertical })} />
}
