import { ButtonAlign, ButtonKind, ButtonSize, ButtonProps } from '@scalis/components/core/button'

import { Routes } from 'src/app/_constants/routes'

type NavigationButtonConfig = {
  searchJob: ButtonProps
  // login: ButtonProps;
  scheduleDemo: ButtonProps
}

export const NAV_BTN_CONFIG: NavigationButtonConfig = {
  searchJob: {
    link: Routes.landing.findJobs,
    kind: ButtonKind.secondary,
    size: ButtonSize.medium,
    align: ButtonAlign.center,
    children: 'Search Jobs',
  },

  // login: {
  //   link: "/login",
  //   kind: ButtonKind.secondary,
  //   size: ButtonSize.medium,
  //   align: ButtonAlign.center,
  //   endAdornment: "fa-regular fa-circle-user",
  //   children: "Login",
  // },

  scheduleDemo: {
    link: Routes.landing.schedule.demo,
    kind: ButtonKind.primary,
    size: ButtonSize.medium,
    align: ButtonAlign.center,
    children: 'Schedule a Demo',
  },
} as const
