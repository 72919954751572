import { tv } from 'tailwind-variants'
import { ConfirmationDialogKind } from './confirmation-dialog.types'

export const iconStyles = tv({
  base: 'fa-solid',
  variants: {
    kind: {
      [ConfirmationDialogKind.danger]: 'fa-triangle-exclamation text-status-critical',
      [ConfirmationDialogKind.information]: 'fa-circle-info text-brand-primary-rest',
    },
  },
  defaultVariants: {
    kind: ConfirmationDialogKind.information,
  },
})
