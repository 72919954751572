import { tv } from 'tailwind-variants'
import { AvatarItemAlignmentEnum, AvatarVariantSizeEnum } from './avatar.types'

export const avatarStyles = tv({
  slots: {
    container: 'flex items-center gap-2',
    avatar: 'ring-2 ring-white',
    avatarFallback: 'flex items-center justify-center font-medium uppercase',
    text: 'text-typography-neutral-primary font-normal',
    textSmall: 'text-xs italic',
  },
  variants: {
    verticalAlignment: {
      [AvatarItemAlignmentEnum.Center]: {
        container: 'flex items-center',
      },
      [AvatarItemAlignmentEnum.Base]: {
        container: 'flex items-baseline',
      },
    },
    size: {
      [AvatarVariantSizeEnum.Small]: {
        avatar: 'h-6 w-6',
        avatarFallback: 'text-[8px]/4',
        text: 'text-sm',
      },
      [AvatarVariantSizeEnum.Default]: {
        avatar: 'h-8 w-8',
        avatarFallback: 'text-xs',
        text: 'text-sm',
      },
      [AvatarVariantSizeEnum.Medium]: {
        avatar: 'h-10 w-10',
        avatarFallback: 'text-xs',
        text: 'text-base',
      },
      [AvatarVariantSizeEnum.Large]: {
        avatar: 'h-12 w-12',
        avatarFallback: 'text-sm/6',
        text: 'text-lg',
      },
      [AvatarVariantSizeEnum.ExtraLarge]: {
        avatar: 'h-14 w-14',
        avatarFallback: 'text-sm/6',
        text: 'text-xl',
      },
      [AvatarVariantSizeEnum.XXL]: {
        avatar: 'h-[72px] w-[72px]',
        avatarFallback: 'text-lg',
        text: 'text-xl',
      },
    },
  },
  defaultVariants: {
    size: AvatarVariantSizeEnum.Default,
  },
})
