import { forwardRef } from 'react'
import Link from 'next/link'
import { cn } from '~/utils/cn'
import { ButtonAlign, ButtonKind, ButtonProps, ButtonStandaloneKind, ButtonWidth } from './button.types'
import { standaloneButtonClassName, buttonClassName } from './styles'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      onClick,
      disabled,
      align = ButtonAlign.center,
      size,
      width,
      children,
      link,
      asChild,
      loading = false,
      startAdornment,
      endAdornment,
      isStandalone = false,
      kind,
      className,
      noWrap = true,
      ...props
    },
    ref,
  ) => {
    const allClassNames = isStandalone
      ? standaloneButtonClassName({
          kind: kind as ButtonStandaloneKind,
          size,
          width,
        })
      : buttonClassName({ kind: kind as ButtonKind, size, width })
    const elementProps = {
      className: cn(allClassNames, className),
      onClick,
      disabled: disabled || loading,
      'aria-label': props['aria-label'] ?? props.title,
    }
    const content = (
      <div
        className={cn('relative flex w-full items-center gap-2', {
          'justify-center': align === ButtonAlign.center || width === ButtonWidth.full,
          'justify-start': align === ButtonAlign.left,
          'justify-end': align === ButtonAlign.right,
          'whitespace-nowrap': noWrap,
        })}
      >
        {startAdornment && (
          <i
            aria-hidden
            aria-label='startAdornment'
            className={cn(startAdornment, 'fa-1x')}
          />
        )}
        <div className='relative w-full'>
          <span className={loading ? 'invisible' : ''}>{text ?? children}</span>
          {loading && (
            <div className='absolute inset-0 flex items-center justify-center'>
              <i
                aria-hidden
                aria-label='loader'
                className={cn('fa-light fa-spinner-scale fa-spin-pulse')}
              />
            </div>
          )}
        </div>
        {endAdornment && (
          <i
            aria-hidden
            aria-label='endAdornment'
            className={cn(endAdornment, 'fa-1x')}
          />
        )}
      </div>
    )

    if (link) {
      const isExternal = link.startsWith('http')
      return isExternal ? (
        <a
          href={link}
          {...elementProps}
          target='_blank'
          rel='noopener noreferrer'
        >
          {content}
        </a>
      ) : (
        <Link
          href={link}
          {...elementProps}
        >
          {content}
        </Link>
      )
    }

    return asChild ? (
      <div {...elementProps}>{content}</div>
    ) : (
      <button
        ref={ref}
        {...elementProps}
        {...props}
      >
        {content}
      </button>
    )
  },
)
Button.displayName = 'Button'
