import { Button, ButtonKind } from '../../button'
import { LoginMenuButtonProps } from './login-menu-button.types'

export const LoginMenuButton = ({ onClick }: LoginMenuButtonProps) => {
  return (
    <Button
      kind={ButtonKind.secondary}
      onClick={onClick}
      aria-label='login menu button'
    >
      Login
    </Button>
  )
}
