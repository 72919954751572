'use client'

import { forwardRef } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '~/utils/cn'
import { TooltipProps } from './tooltip.props'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border border-borders-1 bg-neutral-10 px-2 py-1 text-xs text-typography-high-contrast drop-shadow-sm ',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
    <TooltipRoot delayDuration={props.delayDuration ?? 0}>
      <TooltipTrigger
        data-testid='tooltip-trigger'
        asChild={props.triggerAsChild}
      >
        {props.tooltipTrigger}
      </TooltipTrigger>

      <TooltipContent className={props.tooltipContentClassName}>{props.content}</TooltipContent>
    </TooltipRoot>
  )
}

enum InfoIconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const iconSizeMap: Record<InfoIconSize, string> = {
  [InfoIconSize.XS]: 'fa-xs',
  [InfoIconSize.SM]: 'fa-sm',
  [InfoIconSize.MD]: 'fa-md',
  [InfoIconSize.LG]: 'fa-lg',
}

interface DefaultInfoIconProps {
  size?: InfoIconSize
  className?: string
}

const DefaultInfoIcon: React.FC<DefaultInfoIconProps> = ({ size = InfoIconSize.XS, className }) => (
  <i className={cn('fa-solid fa-circle-info text-icons', iconSizeMap[size], className)} />
)

export { DefaultInfoIcon, Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
