import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const ReportsIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
  notification = false,
}: IconProps & { notification?: boolean }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2584 2.99609H14.001H9.99925H7.49827C5.01195 2.99609 2.99639 5.01165 2.99639 7.49797V9.99896L2.99634 9.99901V16.5017C2.99634 18.5687 4.38938 20.3104 6.28786 20.839C6.67298 20.9463 7.0789 21.0036 7.49821 21.0036H7.49827H16.502H16.502C16.9293 21.0036 17.3427 20.944 17.7344 20.8328C19.6216 20.2969 21.0039 18.5607 21.0039 16.5017V9.99901L21.0038 9.99896V8.74156C20.5345 8.90748 20.0294 8.99776 19.5032 8.99776C17.0174 8.99776 15.0022 6.98258 15.0022 4.49672C15.0022 3.97054 15.0925 3.46545 15.2584 2.99609Z'
        fill='white'
      />
      <path
        d='M14.001 7.99826H10.9998'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9998 12.0002H17.0023'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9998 16.0022H17.0023'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.99801 15.7521C6.93144 15.7513 6.86735 15.7773 6.82027 15.8244C6.77319 15.8715 6.7471 15.9356 6.74792 16.0022C6.74792 16.1403 6.85989 16.2523 6.99801 16.2523C7.13615 16.2523 7.24812 16.1403 7.24812 16.0022C7.24812 15.864 7.13615 15.7521 6.99801 15.7521'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.99801 7.74816C6.93144 7.74735 6.86735 7.77344 6.82027 7.82052C6.77319 7.8676 6.7471 7.93169 6.74792 7.99826C6.74792 8.13639 6.85989 8.24837 6.99801 8.24837C7.13615 8.24837 7.24812 8.13639 7.24812 7.99826C7.24812 7.86014 7.13615 7.74816 6.99801 7.74816'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.99801 11.7501C6.93144 11.7493 6.86735 11.7754 6.82027 11.8225C6.77319 11.8696 6.7471 11.9336 6.74792 12.0002C6.74792 12.1383 6.85989 12.2503 6.99801 12.2503C7.13615 12.2503 7.24812 12.1383 7.24812 12.0002C7.24812 11.8621 7.13615 11.7501 6.99801 11.7501'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />

      {notification && (
        <circle
          xmlns='http://www.w3.org/2000/svg'
          id='Oval'
          cx='19'
          cy='5'
          r='2.50104'
          fill={primaryColor}
        />
      )}
    </svg>
  )
}
