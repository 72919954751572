import { ComponentProps, RefObject } from 'react'
import { EventWithInterview } from '~/types/calendar/events'

import Calendar from '@fullcalendar/react'

import { VIEW_TYPE } from './calendar.constants'

// import type { EventContentArg, EventSourceInput } from "@fullcalendar/core";
// import type { DateClickArg } from "@fullcalendar/interaction";

export const NAVIGATE_TYPE = {
  NEXT: 'next',
  PREV: 'prev',
} as const

export type NavigateType = (typeof NAVIGATE_TYPE)[keyof typeof NAVIGATE_TYPE]

export type ViewType = (typeof VIEW_TYPE)[keyof typeof VIEW_TYPE]

export interface CalendarEventInfo {}

export interface CalendarProps extends ComponentProps<typeof Calendar> {
  className?: string
  ref: RefObject<any>
  calendarEvents: unknown
  hourDisplay?: number
  currentView: ViewType
  resources: any[]
  // getEventAppearance: (event: any) => "";
  onDateClick?: (info: unknown) => void
  onEventResize?: (info: unknown) => void
  onClickEvent?: (eventInfo: unknown) => void
  onDropEvent?: (info: unknown) => void
  calendarLoading?: (loading: boolean) => void
}

/**
 * @deprecated
 * @refactor
 */
export interface CalendarEventData {
  id: string
  nylasCalendarId: number
  userId: number
  calendarEventId: string
  status: 'confirmed' | 'tentative' | 'cancelled'
  title: string
  type: 'MEETING' | 'APPOINTMENT' | 'INTERNAL_CANDIDATE'
  description: string | null
  startTime: string
  endTime: string
  timezone: string
  location: string | null
  repeats: 'DOES_NOT_REPEAT' | 'DAILY' | 'WEEKLY' | 'MONTHLY'
  reminders: any | null
  employers: any | null
  conferencing: {
    details: {
      pin: string
      url: string
      phone: string[]
      meetingCode: string
    }
    provider: string
  }
  masterEventId: number | null
  regardingCandidates: any | null
  regardingJobs: any | null
  internalHrAssociates: any | null
  candidates: any | null
  notes: any | null
  isScalisEvent: boolean
  createdAt: string
  updatedAt: string
  calendarColor: string
  interview?: EventWithInterview['interview']
}
