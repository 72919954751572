import { tv } from 'tailwind-variants'
import { NotificationKind } from './notification.types'

export const notificationVariants = tv({
  slots: {
    contentContainer: 'flex flex-1 items-start justify-between border-l-[3px]  pl-3',
    icon: '',
  },
  variants: {
    kind: {
      [NotificationKind.info]: {
        contentContainer: 'border-bg-brand-primary-dark-00',
        icon: 'fa-solid fa-circle-dot text-brand-primary-rest',
      },
      [NotificationKind.danger]: {
        contentContainer: 'border-status-critical-10',
        icon: 'fa-solid fa-circle-xmark text-status-critical',
      },
      [NotificationKind.success]: {
        contentContainer: 'border-status-success-10',
        icon: 'fa-solid fa-circle-check text-status-success',
      },
      [NotificationKind.warning]: {
        contentContainer: 'border-status-warning-10',
        icon: 'fa-solid fa-circle-exclamation text-status-warning',
      },
    },
  },
  defaultVariants: {
    kind: NotificationKind.info,
  },
})
