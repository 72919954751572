'use client'

import { isServer, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SessionProvider } from 'next-auth/react'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '~/bff/apolloClient'
import { TooltipProvider } from '~/components/v2/Tooltip'
import { NavbarProvider } from '~/scalis-components/core/navbar/navbar-context'
import { CalendarProvider } from '~/src/app/company/(dashboard)/calendar/context'
import { WorkspaceProvider } from './workspace/workspace-provider'

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  })
}

let browserQueryClient: QueryClient | undefined = undefined

function getQueryClient() {
  if (isServer) {
    return makeQueryClient()
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  const queryClient = getQueryClient()

  return (
    <ApolloProvider client={apolloClient}>
      <SessionProvider>
        <NavbarProvider>
          <WorkspaceProvider>
            <TooltipProvider>
              <QueryClientProvider client={queryClient}>
                <CalendarProvider>{children}</CalendarProvider>
              </QueryClientProvider>
            </TooltipProvider>
          </WorkspaceProvider>
        </NavbarProvider>
      </SessionProvider>
    </ApolloProvider>
  )
}
