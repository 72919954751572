import { cn } from '~/utils/cn'
import { Body, BodySize, BodyType, Caption, CaptionType } from '../../typography'
import { tagStyle } from './tag.styles'
import { TagProps } from './tag.types'

export const Tag = ({
  closeButton,
  text,
  small,
  startAdornment,
  endAdornment,
  color = 'neutral',
  outlined,
  ...props
}: TagProps) => {
  const { closeIcon, contentContainer, icons, tag } = tagStyle({
    small,
    color,
    outlined,
  })

  return (
    <div className={tag({ class: props.className })}>
      <div className={contentContainer()}>
        {startAdornment && (
          <i
            aria-label='startAdornment'
            className={cn(startAdornment, icons())}
          />
        )}

        {!small && (
          <Body
            size={BodySize.small}
            type={BodyType.emphasys}
            aria-label={props['aria-label']}
          >
            {text}
          </Body>
        )}
        {small && (
          <Caption
            type={CaptionType.emphasys}
            aria-label={props['aria-label']}
          >
            {text}
          </Caption>
        )}

        {endAdornment && (
          <i
            aria-label='endAdornment'
            className={cn(endAdornment, icons())}
          />
        )}

        {closeButton?.hasCloseButton && (
          <i
            aria-label='close'
            role='button'
            className={closeIcon()}
            onClick={closeButton?.onClose}
          />
        )}
      </div>
    </div>
  )
}
