'use client'

import { Children, cloneElement, isValidElement } from 'react'
import Link from 'next/link'
import { cn } from '~/utils/cn'
import { Body, BodySize, BodyType } from '../typography'
import {
  hrStyles,
  innerTabsListStyles,
  tabsListStyles,
  tabsRootStyles,
  tabsStyles,
  tabsTriggerStyles,
} from './filter-tabs.styles'
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from './tabs-base'
import { TabProps, TabsProps } from './tabs.types'

export const Tabs = ({
  value,
  tabs,
  variant = 'primary',
  className,
  tabsListClassName,
  tabsContentClassName,
  orientation,
  children,
  endTabsListSlot,
  onClick,
}: TabsProps) => {
  const defaultTabId = value ?? tabs.find((tab) => tab.isActive)?.id ?? tabs[0].id
  const isVertical = orientation === 'vertical'

  const renderTriggerBody = ({ title, icon }: TabProps, borderless: boolean = false) => {
    const showSecondaryIcon = variant === 'secondary' && icon

    return (
      <div className='text-center'>
        {showSecondaryIcon && (
          <div className='mb-1 inline-flex h-10 w-10 items-center justify-center rounded-xl p-2 text-icon-neutral-00 group-data-[state=active]:bg-brand-primary-dark-00 group-data-[state=inactive]:bg-gray-200'>
            <i className={cn(icon, 'w-4 text-base font-normal group-data-[state=inactive]:text-neutral-disabled')} />
          </div>
        )}
        <div className='flex flex-row items-center'>
          <Body
            size={BodySize.small}
            type={isVertical ? BodyType.basic : BodyType.emphasys}
            className={tabsStyles({ variant })}
          >
            {title}
          </Body>
          {variant === 'tertiary' && (
            <hr
              className={hrStyles({
                borderless,
              })}
            />
          )}
        </div>
      </div>
    )
  }

  const renderTabContent = () => {
    if (children) {
      return Children.map(children, (child) => {
        if (!isValidElement(child) || child.type !== TabsContent) return null
        return tabs.some((tab) => tab.id === (child.props as any).value) ? cloneElement(child) : null
      })
    }

    const hasContent = tabs.some((tab) => tab.content)
    if (hasContent) {
      return (
        <div className='flex flex-grow overflow-x-hidden'>
          {tabs.map((tab) => (
            <TabsContent
              className={cn('w-full', tabsContentClassName)}
              key={`tab-content-${tab.id}`}
              value={tab.id}
            >
              {tab.content}
            </TabsContent>
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <TabsRoot
      className={cn(tabsRootStyles({ isVertical }), className)}
      defaultValue={defaultTabId}
      orientation={orientation}
    >
      <TabsList className={cn(tabsListStyles({ variant, isVertical }), tabsListClassName)}>
        <div className={innerTabsListStyles({ variant, isVertical })}>
          {tabs.map((tab, index) => {
            const activeIndex = tabs.findIndex((tab) => tab.isActive)
            const borderless =
              tab.isActive || value === tab.id || activeIndex - index === 1 || index === tabs.length - 1
            return (
              <TabsTrigger
                key={`tab-header-${tab.id}`}
                onClick={() => onClick?.(tab.id)}
                value={tab.id}
                asChild={tab.href != null}
                className={tabsTriggerStyles({ variant, isVertical })}
              >
                {tab.href ? (
                  <Link href={tab.href}>{renderTriggerBody(tab, borderless)}</Link>
                ) : (
                  renderTriggerBody(tab, borderless)
                )}
              </TabsTrigger>
            )
          })}
        </div>

        {endTabsListSlot}
      </TabsList>

      {renderTabContent()}
    </TabsRoot>
  )
}
