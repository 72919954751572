'use client'

import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CommandLoading } from 'cmdk'
import useDebounce from '~/hooks/useDebounce'
import { cn } from '~/utils/cn'
import { normalizeString } from '~/utils/normalize-string'
import { Command, CommandGroup, CommandItem, CommandList } from '../../command'
import { Icon } from '../../icon'
import { Popover, PopoverContent, PopoverTrigger } from '../../popover'
import { Body, BodyColor, BodySize } from '../../typography'
import { Input } from '../default'
import { InputSuggestionsProps } from './input-suggestions.types'

const InputSuggestionsInternal = <T,>(
  {
    suggestions,
    onDebouncedChange,
    getSuggestionLabel,
    onSelectSuggestion,
    emptyMessage = 'Nothing found.',
    searchPlaceholder = 'Search...',
    loadingMessage = 'Loading',
    loading = false,
    debounceDelay = 300,
    value,
    onKeyDown,
    ...props
  }: InputSuggestionsProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const [open, setOpen] = useState(false)
  const internalInputRef = useRef<HTMLInputElement | null>(null)
  const debouncedValue = useDebounce(String(value), debounceDelay)

  const getLabel = useCallback(
    (item: T) => {
      return getSuggestionLabel ? getSuggestionLabel(item) : String(item)
    },
    [getSuggestionLabel],
  )

  const filteredSuggestions = useMemo(() => {
    const normalizedInputValue = normalizeString(value ? String(value) : '')
    return suggestions.filter((item) => normalizeString(getLabel(item)).includes(normalizedInputValue))
  }, [suggestions, value, getLabel])

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') setOpen(false)
    onKeyDown?.(e)
  }

  const preventAutoFocus = (e: Event) => e.preventDefault()

  const handleToggleOpen = (open: boolean) => {
    setOpen(open)
    internalInputRef.current?.focus()
  }

  useEffect(() => {
    onDebouncedChange?.(debouncedValue)
  }, [onDebouncedChange, debouncedValue])

  return (
    <Popover
      open={open}
      onOpenChange={handleToggleOpen}
    >
      <PopoverTrigger>
        <Input
          value={value}
          onKeyDown={handleOnKeyDown}
          ref={(e) => {
            internalInputRef.current = e

            if (typeof ref === 'function') {
              ref(e)
            } else if (ref) {
              ref.current = e
            }
          }}
          {...props}
        />
      </PopoverTrigger>

      <PopoverContent
        sideOffset={0}
        onOpenAutoFocus={preventAutoFocus}
        onCloseAutoFocus={preventAutoFocus}
        style={{ width: internalInputRef.current?.getBoundingClientRect().width }}
        className='p-0 border-neutral-00 overflow-hidden rounded-xl shadow-lg'
      >
        <Command
          shouldFilter={false}
          loop
        >
          <CommandList className='flex flex-col bg-neutral-00 p-2 max-h-56'>
            {loading && (
              <CommandLoading className='px-2'>
                <div className='flex items-center gap-2'>
                  <Icon
                    className='fa-spin text-brand-primary-rest'
                    icon='fa-regular fa-spinner-third'
                    style={{ '--fa-animation-duration': '1s' }}
                  />
                  <Body
                    size={BodySize.small}
                    color={BodyColor.secondary}
                  >
                    {loadingMessage}
                  </Body>
                </div>
              </CommandLoading>
            )}

            {!loading && !filteredSuggestions.length && (
              <div className={cn('px-2', { 'py-8': debouncedValue })}>
                <Body
                  size={BodySize.small}
                  color={BodyColor.secondary}
                  center={!!debouncedValue}
                >
                  {debouncedValue ? emptyMessage : searchPlaceholder}
                </Body>
              </div>
            )}

            {!loading && filteredSuggestions.length > 0 && (
              <CommandGroup className='p-0'>
                {filteredSuggestions.map((option) => {
                  const label = getLabel(option)

                  return (
                    <CommandItem
                      key={label}
                      value={label}
                      title={label}
                      className='cursor-pointer gap-2 rounded-lg p-2 hover:bg-button-subtle-hover active:bg-button-subtle-pressed'
                      onSelect={() => {
                        onSelectSuggestion?.(option)
                        setOpen(false)
                      }}
                    >
                      {label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const InputSuggestions = forwardRef(InputSuggestionsInternal) as <T>(
  props: InputSuggestionsProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof InputSuggestionsInternal>
