'use client'

import { Drawer } from '@scalis/components/core/drawer'
import Link from 'next/link'
import { Logo } from '../shared/Logo'
import { NAV_BTN_CONFIG } from '../navbar/navbar.constants'
import { Button } from '@scalis/components/core/button'
import { LoginButton } from '../shared/LoginButton'
import { AccountDropdownMenu } from '~/scalis-components/core/navbar/account-dropdown-menu/account-dropdown-menu'
import { Divider } from '~/scalis-components/core/divider'
import { AccountDropdownMenuFooter } from '~/scalis-components/core/navbar/account-dropdown-menu/components/footer'
import { useNavLinks } from '~/scalis-components/team/navbar-variations/default/navbar-default.hooks'
import { useSession } from 'next-auth/react'
import { User } from 'next-auth'
import { usePathname } from 'next/navigation'
import { Routes } from 'src/app/_constants/routes'
import SubdomainLink from '~/scalis-components/core/subdomain-link/subdomain-link'
import { CompanyAvatar } from '~/scalis-components/core/company-avatar'
import { useSubdomainCompanyInfo } from '~/src/app/jobs/hooks/use-subdomain-company-info'
interface NavDrawerProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

const NavigationLinks = ({ onNavigate }: { onNavigate: () => void }) => {
  const navLinks = useNavLinks()
  const pathname = usePathname()

  return (
    <div className='flex flex-col gap-4'>
      {navLinks
        .filter((link) => link.href !== pathname)
        .map(({ label, href }, index) => (
          <Link
            role='link'
            aria-label={label}
            href={href}
            key={`navbar-link-index-${index}`}
            onClick={onNavigate}
          >
            {label}
          </Link>
        ))}
    </div>
  )
}

const GuestActions = () => (
  <div className='my-4 grid grid-cols-2 gap-2'>
    <LoginButton />
    <Button
      {...NAV_BTN_CONFIG.scheduleDemo}
      className='whitespace-nowrap'
    />
  </div>
)

export const NavDrawer = ({ isOpen, onOpenChange }: NavDrawerProps) => {
  const { status, data: session } = useSession()
  const user = session?.user
  const isAuthenticated = status === 'authenticated' && user?.emailVerified
  const info = useSubdomainCompanyInfo()

  const handleNavigate = () => {
    onOpenChange(false)
  }

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title=''
      hideFooterContent
      handlerOnHeaderCloseButtonClick={() => onOpenChange(false)}
      handlerOnOverlayClick={() => onOpenChange(false)}
      contentClassName='p-0'
    >
      <div className='flex flex-col p-4'>
        <nav className='flex flex-col'>
          <div className='mb-6'>
            {info ? (
              <SubdomainLink
                subdomain={info.subdomain ?? ''}
                href={Routes.jobs_view}
              >
                <CompanyAvatar
                  size='md'
                  imageSrc={info.logo}
                  name={info.companyName ?? info.subdomain}
                />
              </SubdomainLink>
            ) : (
              <Logo />
            )}
          </div>
          <div className='flex flex-1 flex-col'>
            <NavigationLinks onNavigate={handleNavigate} />
            {!isAuthenticated && <GuestActions />}
          </div>
        </nav>
      </div>

      <div className='h-px w-full bg-gray-200' />
      {isAuthenticated && (
        <>
          <UserProfile user={user} />
          <Divider />
        </>
      )}

      <div className='p-4'>
        <div className='flex flex-col gap-4 text-sm text-neutral-secondary'>
          <Link href={Routes.landing.legal.termsOfService}>Terms and Conditions</Link>
          {!isAuthenticated && <Link href={Routes.landing.legal.privacyPolicy}>Privacy Policy</Link>}
          <Link href={Routes.landing.legal.dpa}>Data Processing Agreement (DPA)</Link>
        </div>
      </div>

      <Divider />
      {isAuthenticated && (
        <>
          <AccountDropdownMenuFooter />
        </>
      )}
    </Drawer>
  )
}

export const UserProfile = ({ user }: { user: User }) => {
  if (!user) return null

  return (
    <div className='flex items-center justify-between p-4'>
      <AccountDropdownMenu
        email={user?.email ?? ''}
        imageSrc={user?.image ?? ''}
        name={user?.name ?? ''}
      />
      <div className='ml-2 flex-1'>{user?.name}</div>
    </div>
  )
}
