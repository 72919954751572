import Link from 'next/link'
import { FC, useEffect, useState } from 'react'
import { getSubdomainUrl } from '~/hooks/use-subdomain-router'

type Props = {
  subdomain: string
  href: string
  children?: React.ReactNode | undefined
  onClick?: (e: any) => void
  className?: string
}

const SubdomainLink: FC<Props> = ({ subdomain, href, children, onClick, className }) => {
  const [subdomainUrl, setSubdomainUrl] = useState<string>('')

  useEffect(() => {
    const url = getSubdomainUrl(href, subdomain)
    setSubdomainUrl(url)
  }, [subdomain, href])

  return (
    <Link
      href={subdomainUrl}
      onClick={onClick}
      className={className}
    >
      {children}
    </Link>
  )
}

export default SubdomainLink
