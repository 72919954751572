import { tv } from 'tailwind-variants'
import { TitleSize, TitleType } from './title.types'

export const titleClassName = tv({
  base: 'font-medium text-neutral-primary',
  variants: {
    size: {
      [TitleSize.extraLarge]: 'text-[32px]/10 tracking-[-1.6px]',
      [TitleSize.large]: 'text-2xl/8 tracking-[-1.2px]',
      [TitleSize.medium]: 'text-xl/8 tracking-[-0.8px]',
      [TitleSize.small]: 'text-lg/6 tracking-[-0.8px]',
      [TitleSize.extraSmall]: 'text-base tracking-[-0.6px]',
    },
    type: {
      [TitleType.emphasys]: 'text-brand-primary-rest',
      [TitleType.basic]: 'text-neutral-primary',
      [TitleType.muted]: 'text-neutral-secondary',
      [TitleType.light]: 'font-light',
    },
    center: { true: 'text-center' },
  },
  defaultVariants: {
    size: TitleSize.medium,
    type: TitleType.basic,
  },
})
