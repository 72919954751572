'use client'

import { Avatar, AvatarItemAlignmentEnum } from '~/scalis-components/core/avatar'
import { AVATAR_GROUP_COLORS_ARRAY } from '~/scalis-components/core/avatar/avatar-group/avatar-group.constants'
import { getHexColor } from '~/scalis-components/core/avatar/avatar-group/avatar-group.utils'
import { AccountButtonProps } from '../../account-button.types'

export const AccountAvatar = ({
  imageSrc = '',
  name,
  avatarClassName,
  backgroundColor,
}: Partial<AccountButtonProps>) => {
  const randomIndex = Math.floor(Math.random() * AVATAR_GROUP_COLORS_ARRAY.length)
  return (
    <Avatar
      src={imageSrc}
      fullName={name}
      backgroundColor={backgroundColor ?? getHexColor(randomIndex, AVATAR_GROUP_COLORS_ARRAY)}
      className={avatarClassName}
      verticalAlignment={AvatarItemAlignmentEnum.Base}
    />
  )
}
