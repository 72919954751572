'use client'

import { useState } from 'react'
import { HamburgerMenuButton } from '@scalis/components/core/navbar/hamburger-menu-button/hamburger-menu-button'
import { NavDrawer } from '../nav-drawer/nav-drawer'

export function MobileNav() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className='lg:hidden'>
        <HamburgerMenuButton onClick={() => setIsOpen(true)} />
      </div>
      <NavDrawer
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </>
  )
}
