export const ICON_SIZE = {
  WIDTH: 24,
  HEIGHT: 24,
} as const

export const ICON_COLORS = {
  PRIMARY: {
    DEFAULT: '#0021F8',
    ACTIVE: '#0021F8',
  },
  SECONDARY: {
    DEFAULT: '#BFCFFA',
    ACTIVE: '#FFFFFF',
  },
} as const

export const DEFAULT_ICON_CONFIG = {
  width: ICON_SIZE.WIDTH,
  height: ICON_SIZE.HEIGHT,
  primaryColor: {
    default: ICON_COLORS.PRIMARY.DEFAULT,
    active: ICON_COLORS.PRIMARY.ACTIVE,
  },
  secondaryColor: {
    default: ICON_COLORS.SECONDARY.DEFAULT,
    active: ICON_COLORS.SECONDARY.ACTIVE,
  },
}
