import { useEffect, useState } from 'react'
import { useGetSignedUrl } from './use-get-signed-url'

export const useSignedAWSUrl = (awsUrl: string | null | undefined) => {
  const [url, setUrl] = useState<string | null>(null)
  const { mutate } = useGetSignedUrl({
    onSuccess: (response) => setUrl(response.temporaryUrl),
  })

  useEffect(() => {
    if (awsUrl) {
      const pathMatch = awsUrl.match(/amazonaws\.com\/(.+)$/)
      const key = pathMatch ? pathMatch[1] : awsUrl
      mutate({ key })
    }
  }, [awsUrl, mutate])

  return url
}
