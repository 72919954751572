'use client'

export const enableCorsOnImageResponseCache = (src: string, onLoadFinish: VoidFunction) => {
  const img = new Image()
  img.onload = onLoadFinish
  img.onerror = onLoadFinish
  img.crossOrigin = 'anonymous'
  img.src = src
}

export const isSafari = () => {
  if (typeof window !== 'undefined') {
    const ua = window.navigator?.userAgent.toLowerCase()
    const isSafariUA = ua.includes('safari') && !ua.includes('chrome') && !ua.includes('android')
    const isSafariFeature = window.safari !== undefined
    return isSafariUA || isSafariFeature
  }
}
