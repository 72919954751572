import { ICON_COLORS, ICON_SIZE } from './icons.constants'
import { IconProps } from './icons.types'

export const AnalyticsIcon = ({
  width = ICON_SIZE.WIDTH,
  height = ICON_SIZE.HEIGHT,
  primaryColor = ICON_COLORS.PRIMARY.DEFAULT,
  secondaryColor = ICON_COLORS.SECONDARY.DEFAULT,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.5262 12.1824C20.2004 11.8569 19.766 11.6794 19.3021 11.6794H15.5509C15.2152 11.6794 14.9191 11.9062 14.8302 12.2317C14.6822 12.7939 14.386 13.3068 13.9813 13.7211C13.5765 14.1353 13.0632 14.4312 12.5005 14.589C11.9378 14.7468 11.3456 14.7468 10.7829 14.589C10.2202 14.4411 9.70686 14.1452 9.29225 13.7309C8.87764 13.3167 8.5815 12.8038 8.43342 12.2416C8.28535 11.6794 8.28535 11.0876 8.43342 10.5254C8.5815 9.96323 8.88752 9.45035 9.30212 9.04596C9.71673 8.64158 10.2301 8.34568 10.7927 8.19774C11.1185 8.10897 11.3456 7.82294 11.3456 7.47773V3.46347C11.3159 3.24648 11.2567 3.03935 11.1481 2.85195C11.0395 2.66456 10.9013 2.49688 10.7335 2.3588C10.5657 2.22072 10.3683 2.12209 10.161 2.06291C9.95365 2.00373 9.73648 1.98401 9.52917 2.01359C9.49956 2.01359 9.46994 2.01359 9.4502 2.02346C7.78189 2.40812 6.24192 3.23661 4.9981 4.42018C3.75427 5.60375 2.84608 7.09307 2.37224 8.74021C1.89841 10.3873 1.87866 12.1331 2.30314 13.8C2.73749 15.457 3.60619 16.9759 4.81053 18.189C6.02474 19.4022 7.54497 20.2701 9.20341 20.6942C9.99314 20.9014 10.8026 21 11.6121 21C12.5005 21 13.3989 20.8816 14.2577 20.6252C15.9062 20.1518 17.4067 19.2444 18.5815 18.0016C19.766 16.7589 20.5953 15.2202 20.9803 13.5534C20.9901 13.5041 21 13.4449 21 13.3857C21 12.9221 20.8223 12.4882 20.4965 12.1627L20.5262 12.1824Z'
        fill={primaryColor}
      />
      <path
        d='M20.6939 8.22726C20.2201 6.87602 19.4303 5.63327 18.4234 4.61738C17.4165 3.61135 16.1628 2.8223 14.8104 2.34887C14.5834 2.26997 14.3366 2.29956 14.1391 2.4475C13.9417 2.58559 13.8232 2.81244 13.8232 3.04915V7.48753C13.8232 7.70452 13.922 7.91164 14.0799 8.04973C14.4057 8.32589 14.7117 8.63165 14.9881 8.95713C15.1263 9.1248 15.3336 9.21357 15.5508 9.21357H19.993C20.2299 9.21357 20.457 9.09521 20.5952 8.89795C20.7334 8.70069 20.7729 8.45411 20.6939 8.22726Z'
        fill={secondaryColor}
      />
    </svg>
  )
}
