import Link from 'next/link'
import React from 'react'
import useBreakpoint from '~/hooks/useBreakpoint'
import { Divider } from '~/scalis-components/core/divider'
import { Body, BodySize } from '~/scalis-components/core/typography'
import { Routes } from '~/src/app/_constants/routes'
import { cn } from '~/utils/cn'
import { WorkspaceItem } from './workspace-item'
import { WorkspacesDropdownSubmenu } from './workspaces-dropdown-submenu'

interface WorkspacesDropdownMenuContentProps {
  onOpenSubMenu?: (isOpen: boolean) => void
}

export const WorkspacesDropdownMenuContent: React.FC<WorkspacesDropdownMenuContentProps> = ({ onOpenSubMenu }) => {
  const { lg } = useBreakpoint()
  return (
    <div className='flex w-full flex-col'>
      <div className={cn('px-2', { 'pb-4': !lg })}>
        <h1 className='p-2 pt-4 pb-0 text-xs uppercase leading-4 text-neutral-secondary'>Company Account</h1>
        {lg ? <WorkspacesDropdownSubmenu /> : <WorkspaceItem onClick={onOpenSubMenu} />}
      </div>
      <Divider />
      <div className='flex w-full items-center gap-1 p-4 hover:cursor-pointer'>
        <Link
          href={Routes.company.settings.general.company_information}
          className='flex items-center gap-2'
        >
          <i className='fa-regular fa-gear' />
          <Body
            size={BodySize.small}
            className='hover:underline'
          >
            Company Settings
          </Body>
        </Link>
      </div>
    </div>
  )
}
