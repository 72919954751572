import { cn } from '~/utils/cn'
import { LabelProps } from './label.types'

export const Label = ({ done, children }: LabelProps) => {
  return (
    <span
      className={cn('absolute top-[-1.5rem] whitespace-nowrap text-sm font-medium text-neutral-tertiary', {
        'text-brand-primary-10': done,
      })}
    >
      {children}
    </span>
  )
}
