import { tv } from 'tailwind-variants'
import { cn } from '~/utils/cn'
import { ButtonKind } from '../button.types'
import { baseButton } from './base-button.styles'

export const buttonClassName = tv({
  extend: baseButton,
  base: 'text-center disabled:bg-button-disabled disabled:text-neutral-disabled',
  variants: {
    kind: {
      [ButtonKind.combobox]: cn('bg-neutral-00', 'text-sm text-neutral-tertiary'),
      [ButtonKind.primary]: cn(
        'bg-button-brand-primary-dark-rest',
        'hover:bg-button-brand-primary-dark-hover',
        'active:bg-button-brand-primary-dark-pressed',
        'text-neutral-on-brand',
      ),
      [ButtonKind.secondary]: cn(
        'bg-button-subtle-rest',
        'hover:bg-button-subtle-hover',
        'active:bg-button-subtle-pressed',
        'border border-solid border-neutral-10',
        'disabled:border-neutral-disabled disabled:bg-button-subtle-rest',
      ),
      [ButtonKind.tertiary]: cn(
        'bg-button-brand-primary-light-rest',
        'hover:bg-button-brand-primary-light-hover',
        'active:bg-button-brand-primary-light-pressed',
        'text-brand-primary-rest',
      ),
      [ButtonKind.critical]: cn(
        'bg-button-status-critical-rest',
        'hover:bg-button-status-critical-hover',
        'active:bg-button-status-critical-pressed',
        'text-neutral-on-brand',
      ),
    },
  },
  defaultVariants: {
    kind: ButtonKind.primary,
  },
})
