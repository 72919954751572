import { useContext } from 'react'

import { JobRequisitionsContext } from '.'

export const useJobListings = () => {
  const { sortBy, changeSortBy } = useContext(JobRequisitionsContext)

  return {
    sortBy,
    changeSortBy,
  }
}
