import { QueryFunctionContext } from '@tanstack/react-query'
import axios from '~/services/axios.service'

export enum AvatarType {
  companyLogo = 'company_logo',
  companyUser = 'company_user',
  jobSeeker = 'jobseeker',
  businessUsers = 'businessUsers',
}

export const fetchAvatar = (
  { signal }: QueryFunctionContext,
  {
    id,
    type,
  }: {
    id?: number | null
    type?: AvatarType
  },
): Promise<string | undefined> => {
  if (!id || !type) return Promise.resolve(undefined)
  return axios
    .get('/api/generateTemporaryUrl', {
      signal,
      params: {
        key: `assets/${type}/${id}/avatar.jpg`,
      },
    })
    .then((res) => res.data.temporaryUrl)
    .catch(() => {})
}
