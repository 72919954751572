'use client'

import { FileItemProps } from './file-item.types'
import { Icon } from '~/scalis-components/core/icon'

export const FileItem = ({ name, handleRemove }: FileItemProps) => {
  return (
    <div className='flex w-full items-center justify-between '>
      <div className='flex items-center gap-1'>
        <i className='fa-regular fa-file w-4 text-brand-primary-rest' />
        <span className='text-ellipsis text-brand-primary-rest underline'>{name}</span>
      </div>
      <div className='ml-2 flex justify-end gap-x-2'>
        <button
          type='button'
          onClick={handleRemove}
          aria-label='Remove file'
        >
          <Icon icon='fa-regular fa-trash' />
        </button>
      </div>
    </div>
  )
}
