import Link from 'next/link'

export enum ButtonLinkSize {
  default = 'default',
  large = 'large',
}

export enum ButtonLinkType {
  default = 'default',
  disabled = 'disabled',
  critical = 'critical',
}

export interface ButtonLinkProps extends Omit<React.ComponentProps<typeof Link>, 'className'> {
  size?: ButtonLinkSize
  type?: ButtonLinkType
  icon?: string
}
