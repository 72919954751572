'use client'

import { useEffect, useState } from 'react'
import { Notification, UseNotificationAction, UseNotificationProps } from './'
import { NOTIFICATION_LIMIT, NOTIFICATION_REMOVE_DELAY } from './notification.constants'

let count = 0

function genId() {
  count = (count + 1) % Number.MAX_VALUE
  return count.toString()
}

interface State {
  notifications: UseNotificationProps[]
}

const notificationTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

const addToRemoveQueue = (notificationId: string) => {
  if (notificationTimeouts.has(notificationId)) {
    return
  }

  const timeout = setTimeout(() => {
    notificationTimeouts.delete(notificationId)
    dispatch({
      type: 'REMOVE_NOTIFICATION',
      notificationId: notificationId,
    })
  }, NOTIFICATION_REMOVE_DELAY)

  notificationTimeouts.set(notificationId, timeout)
}

const reducer = (state: State, action: UseNotificationAction): State => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [action.notification, ...state.notifications].slice(0, NOTIFICATION_LIMIT),
      }

    case 'UPDATE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.map((t) =>
          t.id === action.notification.id ? { ...t, ...action.notification } : t,
        ),
      }

    case 'DISMISS_NOTIFICATION': {
      const { notificationId } = action

      // ! Side effects ! - This could be extracted into a dismissNotification() action,
      // but I'll keep it here for simplicity
      if (notificationId) {
        addToRemoveQueue(notificationId)
      } else {
        state.notifications.forEach((notification) => {
          addToRemoveQueue(notification.id)
        })
      }

      return {
        ...state,
        notifications: state.notifications.map((t) =>
          t.id === notificationId || notificationId === undefined
            ? {
                ...t,
                open: false,
              }
            : t,
        ),
      }
    }
    case 'REMOVE_NOTIFICATION':
      if (action.notificationId === undefined) {
        return {
          ...state,
          notifications: [],
        }
      }
      return {
        ...state,
        notifications: state.notifications.filter((t) => t.id !== action.notificationId),
      }
  }
}

const listeners: Array<(state: State) => void> = []

let memoryState: State = { notifications: [] }

function dispatch(action: UseNotificationAction) {
  memoryState = reducer(memoryState, action)
  listeners.forEach((listener) => {
    listener(memoryState)
  })
}

type Notification = Omit<UseNotificationProps, 'id'>

export function notification({ ...props }: Notification) {
  const id = genId()

  const update = (props: UseNotificationProps) =>
    dispatch({
      type: 'UPDATE_NOTIFICATION',
      notification: { ...props, id },
    })
  const dismiss = () => dispatch({ type: 'DISMISS_NOTIFICATION', notificationId: id })

  dispatch({
    type: 'ADD_NOTIFICATION',
    notification: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss()
      },
    },
  })

  return {
    id: id,
    dismiss,
    update,
  }
}

export function useNotification() {
  const [state, setState] = useState<State>(memoryState)

  useEffect(() => {
    listeners.push(setState)
    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [state])

  return {
    ...state,
    notification,
    dismiss: (notificationId?: string) => dispatch({ type: 'DISMISS_NOTIFICATION', notificationId }),
  }
}
